import { RefObject, useEffect } from "react";

export const useFocusOnMount = (
  ref: RefObject<HTMLElement>,
  focusOnMount: boolean,
  options: {
    /**
     * A boolean value indicating whether or not the browser should scroll the document to bring the newly-focused element into view.
     */
    preventScroll?: boolean;
    /**
     * A boolean value that should be set to true to force, or false to prevent visible indication that the element is focused.
     */
    focusVisible?: boolean;
  } = { preventScroll: true }
): void => {
  useEffect(() => {
    if (focusOnMount && ref.current) {
      ref.current.focus(options);
    }
  }, []);
};
