import React, { ComponentProps, useCallback } from "react";
import { defaultLookupId } from "../../utils";
import { useItemsMap, useSelectedIds, useSelectedItems } from "../../../hooks";
import {
  WrappedListComponentType,
  ListWithDataProps,
  BaseListProps,
  ListProps
} from "../types";
import { styles } from "../styles";
import Button from "../../Button";

/**
 * Simple type guard to determine if a List component props include selectable items.
 */
export function isListWithSelectableItems<T>(
  props: BaseListProps | ListWithDataProps<T>
): props is ListProps<T> & Pick<Required<ListWithDataProps<T>>, "selected"> {
  return "selected" in props && !!props.selected;
}

/**
 * Higher Order Component that adds selecting functionality to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A new component that can handle selecting, with the initial + additional props
 */
export function listWithSelectableItems<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  /**
   * List component that includes selecting functionality.
   * @param props The props for the list component. {@link ListWithDataProps}
   */
  const Component = (props: ListWithDataProps<T>) => {
    const { data, header, selected, itemProps } = props;

    const { selectedIds, fixed } = useSelectedIds(
      selected?.value,
      selected?.fixed
    );

    const lookupId = useCallback(
      (item: T) => {
        if (itemProps?.id) {
          return itemProps.id(item) as string;
        }
        return defaultLookupId(item) as string;
      },
      [itemProps?.id]
    );

    const itemsMap = useItemsMap(data, lookupId);
    const selectedItems = useSelectedItems(itemsMap, lookupId, selectedIds);

    const handleItemClick = useCallback(
      (e: React.MouseEvent<HTMLElement>, item: T) => {
        const id = lookupId(item);

        if (
          fixed?.has(id) ||
          (selected?.max && selectedIds.length >= selected?.max)
        ) {
          return;
        }
        const nextSelectedIds = selectedIds.includes(id)
          ? [...selectedIds].filter(selectedId => selectedId !== id)
          : [...selectedIds, id];
        itemProps?.onClick?.(e, item);
        selected?.onChange?.(nextSelectedIds);
      },
      [selectedIds, fixed, selected?.max, lookupId]
    );

    const showCount = !selected?.hideCount && selectedIds.length;
    const showClearButton =
      !selected?.cannotClear && selectedIds.length > (fixed?.size || 0);

    return (
      <WrappedComponent
        {...(props as ComponentProps<typeof WrappedComponent>)}
        header={
          !!(header || showCount) && (
            <>
              {!!header && header}
              {showCount && (
                <div
                  data-testid="list-info-selected-count"
                  css={[styles.selectedCount]}
                >
                  <span>{`${selectedIds.length} selected`}</span>
                  {showClearButton && (
                    <Button
                      variant="ghost"
                      onClick={() => selected?.onChange?.([])}
                    >
                      Clear
                    </Button>
                  )}
                </div>
              )}
            </>
          )
        }
        selectedItems={selectedItems}
        selectedIds={selectedIds}
        itemProps={{
          ...(itemProps || {}),
          id: lookupId,
          onClick:
            itemProps?.onClick || selected?.onChange
              ? handleItemClick
              : undefined
        }}
      />
    );
  };

  return Component;
}
