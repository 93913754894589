import { useEffect, useState } from "react";
import { peerChangesObservable } from "./observable";
/**
 * Hook that provides changes as a React state variable.
 * (Mad-hooks will not track changes if this hook is never used.)
 * @param options Object containing Peer Changes Options.
 * @returns { saved?: PeerSave }
 */
export const usePeerChanges = (options) => {
    const [changes, setChanges] = useState({});
    useEffect(() => {
        const subscription = peerChangesObservable.subscribe({
            next: (peerChange) => {
                /**
                 * If the `dataTypes` option was provided, ignore any changes to data types not listed.
                 * Note: This does not prevent Firestore data requests. This logic should be moved into a
                 * structure that allows the entire feature to be disabled, which may require some structural
                 * changes to Mad-Hooks or Mad-SDK first.
                 */
                if (options?.dataTypes &&
                    peerChange.saved?.dataTypeId &&
                    !options?.dataTypes?.includes(peerChange.saved?.dataTypeId)) {
                    return;
                }
                setChanges(peerChange);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);
    return changes;
};
