import { FC, useMemo, useRef } from "react";
import { InputGroup } from "@blueprintjs/core";
import { v4 as uuid } from "uuid";
import { styles } from "./styles";
import { InputTextProps } from "./types";
import { getIconFromStringOrElement } from "../Icons/utils";
import { useFocusOnMount } from "../../hooks";

/**
 * `<Input type={"text" | "email" | "password"} value={string | undefined} />` allows users to input text.
 */
export const InputText: FC<InputTextProps> = ({
  type = "text",
  disabled = false,
  id = `kit-${uuid()}`,
  placeholder = "",
  focusOnMount = false,
  error = false,
  value = "",
  name,
  prefix,
  suffix,
  onChange,
  onKeyDown,
  onInput,
  onKeyUp,
  onFocus,
  onBlur,
  onClick
}: InputTextProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const PrefixIcon = useMemo(() => {
    return getIconFromStringOrElement(prefix, { size: "medium" });
  }, [prefix]);

  const SuffixIcon = useMemo(() => {
    return getIconFromStringOrElement(suffix, { size: "medium" });
  }, [suffix]);

  useFocusOnMount(inputRef, focusOnMount);

  return (
    <div
      className="subKit-InputText"
      css={[
        styles.inputBase,
        !!prefix && styles.withPrefix,
        !!suffix && styles.withSuffix,
        !!disabled && styles.disabled,
        !!error && styles.withError
      ]}
    >
      <InputGroup
        inputRef={inputRef}
        value={value}
        disabled={disabled}
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        leftElement={PrefixIcon || undefined}
        rightElement={SuffixIcon || undefined}
        onChange={e => onChange(e.currentTarget.value)}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onInput={onInput}
      />
    </div>
  );
};
