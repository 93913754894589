import React, { Fragment, useMemo } from "react";
import { DoNotCare } from "../../../types";
import {
  WrappedListComponentType,
  WrappedListComponentProps,
  ListProps,
  ListChildElement
} from "../types";
import useVirtualScroll from "../../../hooks/useVirtualScroll";
import { styles } from "../styles";
import { isListWithSelectableItems } from "./withSelectableItems";

/**
 * Simple type guard to determine if a List component props include virtual scrolling.
 */
export function isListWithVirtualScroll<T>(
  props: ListProps<T>
): props is ListProps<T> &
  (Pick<Required<ListProps<T>>, "virtualizedProps"> | { isVirtualized: true }) {
  if (props.isVirtualized) {
    return true;
  }
  return !!props.virtualizedProps && props.isVirtualized !== false;
}

/**
 * Determines if the first child of a list is a List.Header component.
 */
export function isListWithHeaderInChildren(props: DoNotCare) {
  return (
    Array.isArray(props?.children) &&
    props?.children[0]?.type?.displayName === "List.Header"
  );
}

/**
 * Higher Order Component that adds virtual scrolling functionality to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A List component that can handle virtual scrolling, with the initial + additional props
 */
function listWithVirtualScroll<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  function Component(props: ListProps<T>) {
    const {
      virtualizedProps,
      children = [],
      role = isListWithSelectableItems(props) ? "listbox" : "list"
    } = props;
    const { sectionIndices } = props as WrappedListComponentProps<T>;

    const { itemSize = 32 } = virtualizedProps || {};

    const childrenArr = (
      Array.isArray(children) ? children : []
    ) as ListChildElement[];

    const {
      setOuterRef,
      setInnerRef,
      items: virtualItems
    } = useVirtualScroll({
      stickyIndices: sectionIndices,
      overscanCount: 20,
      ...(virtualizedProps || {}),
      itemSize,
      count: childrenArr.length || 10
    });

    return (
      <WrappedComponent {...(props as DoNotCare)}>
        <div
          className="list-scroll-area"
          ref={setOuterRef}
          css={[styles.scrollArea]}
        >
          <ul ref={setInnerRef} role={role} css={[styles.itemsList]}>
            {virtualItems.map(({ index, measureRef }) => {
              const child = childrenArr[index];
              if (!child) {
                return null;
              }
              return React.cloneElement(child, {
                ref: measureRef,
                key: index
              });
            })}
          </ul>
        </div>
      </WrappedComponent>
    );
  }
  return Component;
}

/**
 * Higher Order Component that adds ul container with static scrolling to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A List component that can handle static scrolling, with the initial + additional props
 */
function listWithStaticScroll<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  /**
   * List component that includes the ul container with static scrolling.
   * @param props The props for the list component. {@link ListProps}
   */
  function Component(props: ListProps<T>) {
    const {
      children,
      role = isListWithSelectableItems(props) ? "listbox" : "list"
    } = props;

    const childrenArr = (
      Array.isArray(children) ? children : []
    ) as ListChildElement[];

    return (
      <WrappedComponent {...(props as DoNotCare)}>
        <ul
          role={role}
          className="list-scroll-area"
          css={[styles.scrollArea, styles.itemsList]}
        >
          {childrenArr}
        </ul>
      </WrappedComponent>
    );
  }
  return Component;
}

/**
 * Higher Order Component that pulls List.Header from children and adds it to the header prop.
 * This is to keep the list header above the list items when scrolling.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A List component that extracts the header from children, and adds it to the header prop
 */
function listWithHeaderInChildren<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  return function Component(props: ListProps<T>) {
    let { children, header } = props as DoNotCare;
    if (isListWithHeaderInChildren(props)) {
      header = (
        <>
          {children[0]?.props?.children}
          {header?.props?.children}
        </>
      );

      children = children.slice(1);
    }

    return (
      <WrappedComponent {...(props as DoNotCare)} header={header}>
        {children as DoNotCare}
      </WrappedComponent>
    );
  };
}

/**
 * Higher Order Component that adds wrapper and applies virtual or static scrolling to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A List component that can handle scrolling, with the initial + additional props
 */
export function withListWrapper<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  return function Component(props: ListProps<T>) {
    const List = useMemo(() => {
      let List = WrappedComponent;

      if (isListWithVirtualScroll(props)) {
        List = listWithVirtualScroll(List);
      } else {
        List = listWithStaticScroll(List);
      }

      List = listWithHeaderInChildren(List);

      return List;
    }, [isListWithVirtualScroll(props)]);
    return <List {...(props as DoNotCare)} />;
  };
}
