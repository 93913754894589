import { FC } from "react";
import { css } from "@emotion/react";
import { DatePickerProps } from "./types";
import { useCalendar } from "../hooks/useCalendar";
import { CalendarMonth } from "./Calendar/Month";
import { CalendarHeader } from "./Calendar/Header";
import { styles } from "./styles";
import InputTime from "./Time";
import Dropdown from "../../../Dropdown";
import { DATE_PRESET_SECTIONS, DateRangePresetOptions } from "../constants";

const DatePicker: FC<DatePickerProps> = ({
  selected,
  selecting,
  min,
  max,
  time,
  range,
  setEndToEndOfDay,
  presets,
  setSelected,
  setSelecting
}) => {
  const {
    months,
    viewing,
    transitioning,
    error,
    times,
    presetRange,
    nextMonth,
    prevMonth,
    selectDay,
    handleTimeChange,
    setPresetRange
  } = useCalendar({
    selected,
    selecting,
    range,
    min,
    max,
    time,
    setEndToEndOfDay,
    setSelecting,
    setSelected
  });

  return (
    <div css={[styles.base]} className="subKit-DatePicker">
      {range && !!presets?.length && (
        <div css={[styles.datePresetDropdown]}>
          <Dropdown
            isStyled={false}
            minWidth="150px"
            items={presets}
            sections={DATE_PRESET_SECTIONS}
            lookupSection={preset =>
              preset && DateRangePresetOptions[preset]?.section
            }
            lookupId={preset =>
              preset && DateRangePresetOptions[preset]?.action
            }
            lookupLabel={preset =>
              preset && DateRangePresetOptions[preset]?.label
            }
            selected={presetRange}
            onSelect={selectedDateRange => {
              setPresetRange(selectedDateRange);
            }}
          />
        </div>
      )}

      <div
        css={[
          css`
            --month-transition-speed: 0.2s;
          `,
          styles.calendar.base,
          presets && styles.calendar.baseDatePresets,
          transitioning && styles.month.transition[transitioning]
        ]}
      >
        {months.map((month, monthIndex) => (
          <div key={monthIndex} css={styles.month.base}>
            <CalendarHeader
              viewing={viewing[monthIndex]}
              nextMonth={!range || monthIndex === 1 ? nextMonth : undefined}
              prevMonth={!range || monthIndex === 0 ? prevMonth : undefined}
            />

            <CalendarMonth
              month={month}
              viewing={viewing[monthIndex]}
              selected={selected}
              selectDay={selectDay}
              nextMonth={nextMonth}
              prevMonth={prevMonth}
              min={min}
              max={max}
              time={time}
            />

            {time && (
              <InputTime
                hour={times[monthIndex]?.hour}
                minute={times[monthIndex]?.minute}
                second={times[monthIndex]?.second}
                onChange={value => handleTimeChange(monthIndex, value)}
                error={error?.[monthIndex]}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;
