import { css } from "@emotion/react";

export const styles = {
  base: css`
    box-shadow: var(--shadow-3);
    border-radius: 4px;
    border: 1px solid var(--color-border-primary);
    gap: 2px;
    overflow: hidden;
    padding: var(--spacing-4) 0;
    .kit-Input {
      flex-grow: 0;
      padding-bottom: 0;
      .subKit-InputText {
        border-radius: 2px;
      }
    }
  `,
  fill: css`
    width: 100%;
  `
};
