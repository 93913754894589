import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const ThirdPartySegment: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-circled-third-party-segment"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4004 0C4.87709 0 0.400391 4.4767 0.400391 10C0.400391 15.5233 4.87709 20 10.4004 20C15.9237 20 20.4004 15.5233 20.4004 10C20.4004 4.4767 15.9237 0 10.4004 0ZM1.93885 10C1.93885 5.32637 5.72676 1.53846 10.4004 1.53846C15.074 1.53846 18.8619 5.32637 18.8619 10C18.8619 14.6736 15.074 18.4615 10.4004 18.4615C5.72676 18.4615 1.93885 14.6736 1.93885 10Z"
        fill={fill}
      />
      <path
        d="M12.7815 7.22965V9.94361C12.7815 9.99616 12.8114 10.0299 12.8712 10.045C13.1554 10.0825 13.4321 10.1013 13.7013 10.1013C14.3071 10.1013 14.7671 9.96989 15.0812 9.70712C15.4027 9.43685 15.5635 9.05397 15.5635 8.55848C15.5635 7.55998 14.9428 7.06073 13.7013 7.06073C13.4321 7.06073 13.1554 7.0795 12.8712 7.11703C12.8114 7.13205 12.7815 7.16959 12.7815 7.22965ZM11.8616 14.1666C11.6896 14.1666 11.54 14.1028 11.4129 13.9751C11.2932 13.8475 11.2334 13.6974 11.2334 13.5247V6.64406C11.2334 6.46388 11.2932 6.30247 11.4129 6.15983C11.5325 6.01719 11.6821 5.93836 11.8616 5.92334C12.4973 5.86328 13.1105 5.83325 13.7013 5.83325C14.8231 5.83325 15.6645 6.05848 16.2254 6.50893C16.7863 6.95187 17.0667 7.59752 17.0667 8.44586C17.0667 9.3843 16.7938 10.0938 16.2478 10.5742C15.7094 11.0547 14.9091 11.295 13.8472 11.295C13.6004 11.295 13.2751 11.2799 12.8712 11.2499C12.8114 11.2499 12.7815 11.2799 12.7815 11.34V13.5247C12.7815 13.6974 12.7179 13.8475 12.5908 13.9751C12.4636 14.1028 12.3141 14.1666 12.1421 14.1666H11.8616Z"
        fill={fill}
      />
      <path
        d="M5.16362 7.10577C5.00323 7.10577 4.86376 7.04571 4.74521 6.92559C4.62666 6.79797 4.56738 6.64782 4.56738 6.47514C4.56738 6.30247 4.62666 6.15232 4.74521 6.02469C4.86376 5.89707 5.00323 5.83325 5.16362 5.83325H8.87701C9.0374 5.83325 9.17338 5.89707 9.28496 6.02469C9.40351 6.15232 9.46278 6.30247 9.46278 6.47514C9.46278 6.90307 9.31634 7.25592 9.02345 7.5337L7.32889 9.18911C7.32192 9.19661 7.31843 9.20412 7.31843 9.21163C7.31843 9.21914 7.32192 9.22289 7.32889 9.22289H7.49625C8.13781 9.22289 8.64339 9.42184 9.01299 9.81974C9.38258 10.2101 9.56738 10.7582 9.56738 11.4639C9.56738 12.3272 9.32331 12.9954 8.83517 13.4684C8.34702 13.9339 7.64967 14.1666 6.74311 14.1666C6.20616 14.1666 5.68663 14.099 5.18454 13.9639C5.01717 13.9188 4.88119 13.8175 4.77659 13.6598C4.67199 13.5022 4.61968 13.3257 4.61968 13.1305V13.0067C4.61968 12.8565 4.67547 12.7402 4.78705 12.6576C4.9056 12.5675 5.03461 12.545 5.17408 12.59C5.73893 12.7927 6.24102 12.8941 6.68035 12.8941C7.1406 12.8941 7.49625 12.7739 7.7473 12.5337C7.99834 12.2935 8.12387 11.9444 8.12387 11.4864C8.12387 11.066 7.98091 10.7657 7.695 10.5855C7.41606 10.3978 6.92094 10.304 6.20964 10.304H6.09458C5.95511 10.304 5.83307 10.2477 5.72847 10.1351C5.63084 10.0224 5.58203 9.89106 5.58203 9.74091C5.58203 9.36553 5.70755 9.05397 5.9586 8.80622L7.66362 7.13956C7.67059 7.13205 7.67408 7.12454 7.67408 7.11704C7.67408 7.10953 7.67059 7.10577 7.66362 7.10577H5.16362Z"
        fill={fill}
      />
    </svg>
  );
};

export default ThirdPartySegment;
