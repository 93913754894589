import { AnalyticsBrowser } from "@segment/analytics-next";
import { KnownProjectIds, User as MadSDKUser } from "@madhive/mad-sdk";
import { Event, IListener, IUser } from "@madhive/mad-tracker";
import { firebaseProjectId } from "lib/sdk";
import { SegmentProjectID } from "./constants";
import { madTracker } from "../..";

export class Segment implements IListener<MadSDKUser> {
  analytics = new AnalyticsBrowser();

  constructor() {
    let key;
    switch (firebaseProjectId) {
      case KnownProjectIds.MAD_MASTER:
        key = SegmentProjectID.PRODUCTION;
        break;
      case KnownProjectIds.MAD_STAGING:
        key = SegmentProjectID.STAGING;
        break;
      default:
        key = undefined;
        break;
    }

    if (key) {
      this.analytics.load({
        writeKey: key
      });
    }
  }

  user = {
    set: (user: IUser<MadSDKUser>) => {
      if (!user.traits) {
        return;
      }

      const {
        id,
        email,
        name,
        type,
        userType,
        isAdmin,
        isDev,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      } = user.traits;

      this.analytics.identify(user.id, {
        id,
        email,
        name,
        type,
        userType,
        isAdmin,
        isDev,
        primaryOrganizationId,
        primaryOrganizationName,
        brandingId,
        advertiserId,
        agencyId,
        teamId
      });

      if (user.traits?.primaryOrganizationId) {
        this.analytics.group(
          user.traits.primaryOrganizationId,
          user.traits || {}
        );
      }
    },
    reset: () => {
      this.analytics.reset();
    }
  };

  track = ({ event, props }: { event: Event; props: any }) => {
    // Attach the user and org information.
    const user = madTracker.user.get();
    const properties: Record<string, any> = {
      ...props,
      userId: user?.id,
      orgId: user?.traits?.primaryOrganizationId,
      orgName: user?.traits?.primaryOrganizationName
    };

    this.analytics.track(event, properties);
  };

  page = () => {
    const user = madTracker.user.get();
    const properties: Record<string, any> = {
      userId: user?.id,
      orgId: user?.traits?.primaryOrganizationId,
      orgName: user?.traits?.primaryOrganizationName
    };
    this.analytics.page(undefined, undefined, properties);
  };
}
