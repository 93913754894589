import { LookerEmbedDashboard } from "@looker/embed-sdk/lib/dashboard_client";
import { LookerEmbedExplore } from "@looker/embed-sdk/lib/explore_client";
import { LookerEmbedLook } from "@looker/embed-sdk/lib/look_client";
import axios from "axios";
import { SessionAcquireFailure, SessionRegenerationFailure } from "./errors";
import { EmbedBuilder } from "./session";
export class EmbedHandler {
    constructor(sdk, options = {}) {
        /**
         * POSTs Burns to acquire the initial embed session.
         * @return: looker cookieless session data.
         */
        this.acquireSession = () => {
            return axios
                .post(this.urls.embed)
                .then(({ data }) => data)
                .catch((error) => {
                throw new SessionAcquireFailure(error?.message);
            });
        };
        /**
         * PUTs Burns to re-generate necessary tokens for the embed session.
         * @param body: the request body, including the old navigation and api tokens, if any.
         * @return: looker cookieless session data.
         */
        this.regenerateTokens = (body) => {
            return axios
                .put(this.urls.embed, body, {
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(({ data }) => data)
                .catch((error) => {
                throw new SessionRegenerationFailure(error?.message);
            });
        };
        this.urls = {
            embed: `${sdk.urls.burnsBaseUrl}/looker/embed/session`
        };
        this.settings = {
            apiHost: sdk.urls.lookerHost,
            generateTokens: this.regenerateTokens
        };
        // if we're using cookieless sessions, we need to override the default auth method
        // otherwise a URL will be used to obtain a signed URL for embedding dashboards
        if (options.useCookielessSession) {
            this.settings.acquireSession = this.acquireSession;
        }
        else {
            this.settings.auth = {
                url: `${sdk.urls.burnsBaseUrl}/looker/embed/auth`
            };
        }
    }
    /**
     * @param id: the ID of the dashboard to embed.
     * @return: a fluent builder which can embed the iframe. If it is not told explicitly where to append the iframe, it appends it to document.body.
     */
    dashboard(id) {
        return (new EmbedBuilder(this.settings, "dashboard", "/embed/dashboards", LookerEmbedDashboard)
            .withId(id)
            // these enforced .with<Thing>'s here basically ensure that the dashboard properly fills out its height, and doesn't scroll oddly
            .withDynamicIFrameHeight()
            .withDialogScroll()
            .withScrollMonitor());
    }
    explore(id) {
        return new EmbedBuilder(this.settings, "explore", "/embed/explore", LookerEmbedExplore).withId(id);
    }
    look(id) {
        return new EmbedBuilder(this.settings, "look", "/embed/looks", LookerEmbedLook).withId(id);
    }
}
