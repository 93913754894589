import { forwardRef, MutableRefObject, ReactNode } from "react";
import { styles } from "../styles";

/**
 * `List.Header` renders a header section at the top of a list.
 */
const ListHeader = forwardRef(function ListHeader(
  {
    children
  }: {
    children: ReactNode;
  },
  ref: MutableRefObject<HTMLDivElement | null>
) {
  if (!children) {
    return null;
  }
  return (
    <div
      className="kit-ListHeader"
      role="presentation"
      ref={ref}
      css={[styles.listHeader]}
    >
      {children}
    </div>
  );
});

ListHeader.displayName = "List.Header";
export default ListHeader;
