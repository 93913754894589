import { NotImplementedError } from "../../../../errors";
import { parsePacingResponse } from "../../../../models/looker/modules/pacing";
import { DateTime } from "luxon";
import { Handler } from "../../../handlers";
import { PACING_QUERY } from "./query";
class Pacing extends Handler {
    constructor(sdk) {
        super(sdk, "looker-pacing");
    }
    async findItems(filters) {
        const dateFilter = filters.where?.find((filter) => filter.field === "dates")?.value;
        if (!dateFilter) {
            throw new Error("Dates filter is required");
        }
        if (!Array.isArray(dateFilter)) {
            throw new Error("Dates filter must be an array");
        }
        if (dateFilter.length !== 2 || !dateFilter.every((date) => date instanceof Date)) {
            throw new Error("Dates filter must contain two dates");
        }
        const queryBody = {
            ...PACING_QUERY,
            filters: {
                ...PACING_QUERY.filters,
                "vw_dim_lineitem.flt_analysis_interval": this.formatDateFilterForLooker(dateFilter)
            }
        };
        const hashKey = await this.sdk.looker.query.hashQuery(queryBody);
        return this.cache.promise(hashKey, () => this.sdk.looker.query
            .run(queryBody)
            .then((res) => [parsePacingResponse(res)])
            .catch(() => {
            throw new Error("Failed to fetch pacing data");
        }));
    }
    formatDateFilterForLooker(dates) {
        return dates.map((d) => DateTime.fromJSDate(d).toFormat("yyyy/MM/dd")).join(" to ");
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Pacing;
