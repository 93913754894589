import { ReactElement, ReactNode } from "react";
import {
  LookupMethod,
  DoNotCare,
  isIdType,
  IdType,
  IdTypeFromData,
  isReactNode
} from "./types";

export * from "./Input/Date/utils";
export { makeTable } from "./Table/makeTable";

/**
 * @param option: an option to examine.
 * @return
 *   - if an object, a JSON stringified version of the object
 *   - if an array, join the values separating with a comma
 *   - if undefined, the empty string
 *   - else, option.toString
 */
export const defaultLookup: LookupMethod<string> = (
  option?: DoNotCare
): string => {
  if (typeof option === "undefined") {
    return "";
  }

  if (Array.isArray(option)) {
    return option.join(", ");
  }

  return typeof option === "object"
    ? JSON.stringify(option)
    : option.toString();
};

/**
 * @param min: the minimum value.
 * @param value: the value to constrain.
 * @param max: the maximum value.
 * @return: the value, constrained to the inclusive range of [min, max].
 */
export const constrain = (min: number, value: number, max: number) => {
  return Math.max(Math.min(Number(value), max), min);
};

export const defaultLookupId = <
  Data,
  Returns extends IdType = IdTypeFromData<Data>
>(
  data: Data,
  keys: string[] = ["id", "key"]
) => {
  let res: unknown;
  if (isIdType(data)) {
    res = data;
  }
  if (!res && data && typeof data === "object") {
    for (const key of keys) {
      if (key in data && isIdType(data[key as keyof Data])) {
        res = data[key as keyof Data];
        break;
      }
    }
  }
  if (!isIdType(res)) {
    res = JSON.stringify(data);
  }
  return res as Returns;
};

export const defaultLookupLabel = <Data, Returns extends ReactNode = ReactNode>(
  data: Data,
  keys = ["label", "title", "name"]
) => {
  let res: unknown;
  if (isReactNode(data)) {
    res = data;
  } else if (
    (res === undefined || res === null) &&
    data &&
    typeof data === "object"
  ) {
    for (const key of keys) {
      if (key in data && isReactNode(data[key as keyof Data])) {
        res = data[key as keyof Data];
        break;
      }
    }
  }

  if (!isReactNode(res)) {
    res = JSON.stringify(data);
  }

  return res as Returns;
};

export const lookupChildrenByDisplayName = (
  displayName: string,
  children: DoNotCare
) =>
  (Array.isArray(children) ? children : [children]).filter(
    child => child && child?.type?.displayName === displayName
  ) as Array<ReactElement<DoNotCare>>;
