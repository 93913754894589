import { forwardRef, MutableRefObject } from "react";
import { styles } from "../styles";

/**
 * `List.Divider` is a horizontal line that separates items in a list.
 */
const ListDivider = forwardRef(function ListDivider(
  _,
  ref: MutableRefObject<HTMLDivElement | null>
) {
  return (
    <div
      className="kit-ListDivider"
      role="presentation"
      ref={ref}
      css={styles.divider}
    />
  );
});

ListDivider.displayName = "List.Divider";
export default ListDivider;
