import { toLookerQuery } from "../../../models/looker/modules/query";
export class QueryHandler {
    constructor(sdk) {
        this.sdk = sdk;
    }
    /**
     * Runs a query using the Looker SDK. Makes 2 API calls— one to create the query, and another to run it.
     * @param body the query body to run.
     *
     * NB: API calls to create and run queries via Looker SDK are not cached in memory by this function. When using this function in other Looker handlers, be sure to extend the Promise Handler class and wrap calls to this function in a cache promise if you want to cache the results.
     */
    async run(body) {
        try {
            const queryBody = { ...body };
            delete queryBody.id;
            delete queryBody.client_id;
            const newQuery = await this.sdk.looker.sdk.ok(
            // transforms the type safe query to a valid looker query
            this.sdk.looker.sdk.create_query(toLookerQuery(queryBody)));
            if (!newQuery.id) {
                throw new Error("No query id found");
            }
            return await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.run_query({
                query_id: newQuery.id,
                result_format: "json"
            }));
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Hashes the query body to create a unique string key for caching. https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
     * @param query: the query body to hash.
     */
    async hashQuery(query) {
        return this.sdk.cryptography.crypto.subtle
            .digest("SHA-256", new TextEncoder().encode(JSON.stringify(query)))
            .then((buffer) => Array.from(new Uint8Array(buffer))
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""));
    }
}
