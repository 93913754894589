import { useContext } from "react";
import { ToasterContext } from "lib/context";

/**
 * Simple hook for using toast notifications
 * @returns addToast function
 */
export const useToast = () => {
  const { addToast } = useContext(ToasterContext);
  return addToast;
};
