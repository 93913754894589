import { useMemo, FC } from "react";
import { DoNotCare } from "../../types";
import { DropdownProps } from "./types";
import { dropdownWithFlyout, isDropdownWithFlyout } from "./HOC";
import BaseDropdown from "./Base";

function Dropdown<
  Selected extends string[] | string | undefined,
  Item extends Record<string, DoNotCare> | string
>(props: DropdownProps<Selected, Item>) {
  const Dropdown = useMemo(() => {
    let Dropdown = BaseDropdown as FC<DropdownProps<Selected, Item>>;

    if (isDropdownWithFlyout(props)) {
      Dropdown = dropdownWithFlyout<Selected, Item>(BaseDropdown);
    }

    return Dropdown;
  }, [isDropdownWithFlyout(props)]);

  return <Dropdown {...props} />;
}

export default Dropdown;
export * from "./types";
