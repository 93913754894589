export var AffiliationTypes;
(function (AffiliationTypes) {
    AffiliationTypes["STATION"] = "Station";
    AffiliationTypes["ADVERTISER"] = "Advertiser";
    AffiliationTypes["AGENCY"] = "Agency";
    AffiliationTypes["CORPORATE"] = "Corporate";
})(AffiliationTypes || (AffiliationTypes = {}));
export var ArchiveFilter;
(function (ArchiveFilter) {
    ArchiveFilter["ACTIVE"] = "active";
    ArchiveFilter["ARCHIVED"] = "archived";
    ArchiveFilter["BOTH"] = "both";
})(ArchiveFilter || (ArchiveFilter = {}));
export var BrandingId;
(function (BrandingId) {
    BrandingId["MADHIVE"] = "madhive";
    BrandingId["PREMION"] = "premion";
    BrandingId["SCRIPPS"] = "scripps";
    BrandingId["SCRIPPS_VIA_PREMION"] = "scrippsViaPremion";
    BrandingId["RUNNINGMATE"] = "runningmate";
    BrandingId["FUTURESMEDIA"] = "futuresmedia";
    BrandingId["OCTILLION_MEDIA"] = "octillionmedia";
    BrandingId["FOX"] = "fox";
    BrandingId["NEWSY"] = "newsy";
    BrandingId["RESIDENT_HOME"] = "resident-home";
    BrandingId["VOICE_MEDIA"] = "voice-media";
    BrandingId["LEGAL_ACTION_MEDIA"] = "legal-action-media";
    BrandingId["MODUS_DIRECT"] = "modus-direct";
    BrandingId["TEAM_LEWIS"] = "team-lewis";
    BrandingId["KING"] = "king";
    BrandingId["DX_AGENCY"] = "dx-agency";
    BrandingId["LINCOLN_DIGITAL_GROUP"] = "lincoln-digital-group";
    BrandingId["NMPI"] = "nmpi";
    BrandingId["PATHFINDER_INTERACTIVE"] = "pathfinder-interactive";
    BrandingId["CTV_MEDIA"] = "ctv-media";
    BrandingId["IHEART"] = "iheart-radio";
    BrandingId["OMNICOM"] = "omnicom";
    BrandingId["CBC"] = "cbc";
    BrandingId["NEWS_PRESS_GAZETTE"] = "news-press-gazette";
    BrandingId["GROUNDTRUTH"] = "groundtruth";
    BrandingId["COX"] = "cox";
    BrandingId["LOCALFACTOR"] = "localfactor";
    BrandingId["ALLENMEDIA"] = "allenmedia";
    BrandingId["GRIFFIN"] = "griffin";
    BrandingId["HEARST"] = "hearst";
    BrandingId["CAPITOL_BROADCASTING"] = "capitol-broadcasting";
    BrandingId["MCAFEE"] = "mcafee";
    BrandingId["JUICE_MEDIA"] = "juice-media";
    BrandingId["GOOD_KARMA"] = "good-karma";
    BrandingId["MEDIAMATH"] = "mediamath";
    BrandingId["DIGILANT_ARCHIVED"] = "digilant-archived";
    BrandingId["UNIVISION"] = "univision";
    BrandingId["GYDIGITAL"] = "gydigital";
    BrandingId["KATZ_DIGITAL"] = "katz-digital";
    BrandingId["SPARKLIGHT"] = "sparklight";
    BrandingId["HP"] = "hp";
    BrandingId["NOM"] = "nom";
    BrandingId["BEN_HER"] = "ben-her";
    BrandingId["THE_REAL_REAL"] = "the-real-real";
    BrandingId["BRANT_MEDIA"] = "brant-media";
    BrandingId["GAMUT"] = "gamut";
    BrandingId["EVERGREEN"] = "evergreen";
    BrandingId["BAHAKEL"] = "bahakel";
    BrandingId["ANALYTICS_OWL"] = "analytics-owl";
    BrandingId["DLM_AGENCY"] = "dlm-agency";
    BrandingId["MEDICX"] = "medicx";
    BrandingId["TOWNSQUARE"] = "townsquare";
    BrandingId["DIGITAL_ANALYTICS_PRO"] = "digital-analytics-pro";
    BrandingId["BLOCK_COMMUNICATIONS"] = "block-communications";
    BrandingId["TURNER_SPORTS"] = "turner-sports";
    BrandingId["HOMESLICE_GROUP"] = "homeslice-group";
    BrandingId["AVB"] = "avb";
    BrandingId["MONDAY"] = "monday";
    BrandingId["WIN_DIGITAL"] = "win-digital";
    BrandingId["CHARLTON_MARKETING"] = "charlton-marketing";
    BrandingId["TALMONT"] = "talmont";
    BrandingId["FIVE_STAR_MARKETING_ADVISORS"] = "five-star-marketing-advisors";
    BrandingId["EVIDNT"] = "evidnt";
    BrandingId["DISH_SLING"] = "dish-sling";
    BrandingId["T_MOBILE"] = "t-mobile";
    BrandingId["AI_DIGITAL"] = "ai-digital";
    BrandingId["TRIP_ADVISOR"] = "trip-advisor";
    BrandingId["LIFTOFF"] = "liftoff";
    BrandingId["MiQ"] = "miq";
    BrandingId["OMG"] = "omg";
    BrandingId["OTTERA"] = "ottera";
    BrandingId["VOGLIO"] = "vogliomarketing";
    BrandingId["ARKADAS_GROUP"] = "arkadas-group";
    BrandingId["SAKS_OFF_FIFTH"] = "saks-off-fifth";
    BrandingId["BUCKEYE_BROADBAND"] = "buckeye-broadband";
    BrandingId["LIFEBRANDS_D2C"] = "lifebrands-d2c";
    BrandingId["KILL_YOUR_COMPETITION"] = "kill-your-competition";
    BrandingId["SONOBI"] = "sonobi";
    BrandingId["BATEMAN_COLLECTIVE"] = "bateman-collective";
    BrandingId["FEDERATED_DIGITAL_SOLUTIONS"] = "federated-digital-solutions";
    BrandingId["KR8_VENTURES"] = "kr8-ventures";
    BrandingId["ESSENTIALISM_ADVERTISING"] = "essentialism-advertising";
    BrandingId["RED_VENTURES"] = "red-ventures";
    BrandingId["HUDDLED_MASSES"] = "huddled-masses";
    BrandingId["AUTOMATED_TESTING"] = "automated-testing";
    BrandingId["GREEN_AND_WOOD_MEDIA"] = "green-and-wood-media";
    BrandingId["TASTEMADE"] = "tastemade";
    BrandingId["TALON"] = "talon";
    BrandingId["DIGILANT"] = "digilant";
    BrandingId["CHAMPIONSHIP_ADVERTISING"] = "championship-advertising";
    BrandingId["CTV_BUYER"] = "ctv-buyer";
    BrandingId["LEV_LANE"] = "lev-lane";
    BrandingId["THE_DIGITAL_RUCKUS"] = "the-digital-ruckus";
    BrandingId["BRAVE"] = "brave";
    BrandingId["VIDEOHEROES"] = "videoheroes";
    BrandingId["ENTHUSIAST_GAMING"] = "enthusiast-gaming";
    BrandingId["CBS"] = "cbs";
    BrandingId["NIMBUS99"] = "nimbus99";
    BrandingId["CONTINUUM_MEDIA"] = "continuum-media";
    BrandingId["NEXSTAR"] = "nexstar";
    BrandingId["BLAM_RETAIL"] = "blam-retail";
    BrandingId["VICA_AI"] = "vica-ai";
    BrandingId["NIGHTLIFE_TELEVISION"] = "nightlife-television";
    BrandingId["JRR_ADVERTISING"] = "jrr-advertising";
    BrandingId["ESTRELLA_MEDIA"] = "estrella-media";
    BrandingId["PERFORMCB"] = "performcb";
    BrandingId["NEW_ROAD_ADVERTISING"] = "new-road-advertising";
    BrandingId["QUICKTURN_MEDIA"] = "quickturn-media";
    BrandingId["CONNECTADTV"] = "connectadtv";
    BrandingId["NRG_MEDIA"] = "nrg-media";
    BrandingId["LILLY_BROADCASTING"] = "lilly-broadcasting";
})(BrandingId || (BrandingId = {}));
export var CampaignAuditColumns;
(function (CampaignAuditColumns) {
    CampaignAuditColumns["NAME"] = "Name";
    CampaignAuditColumns["ID"] = "MH ID";
    CampaignAuditColumns["VERSION"] = "Version";
    CampaignAuditColumns["UPDATED"] = "Updated";
    CampaignAuditColumns["UPDATED_BY"] = "Updated By";
    CampaignAuditColumns["START_DATE"] = "Start Date";
    CampaignAuditColumns["END_DATE"] = "End Date";
    CampaignAuditColumns["LINE_ITEMS"] = "Line Items (Descendants)";
    CampaignAuditColumns["IMPRESSIONS"] = "Booked Impressions";
    CampaignAuditColumns["DAYPARTS"] = "Dayparts";
    CampaignAuditColumns["DEVICE_CAPS"] = "Device Caps";
    CampaignAuditColumns["PRODUCT_ID"] = "Product ID";
    CampaignAuditColumns["PUB_GROUP_ID"] = "Pub Group ID";
    CampaignAuditColumns["GEO_INCLUDE"] = "Geo Include";
    CampaignAuditColumns["GEO_EXCLUDE"] = "Geo Exclude";
    CampaignAuditColumns["FREQUENCIES"] = "Frequency Caps";
    CampaignAuditColumns["SEGMENTS"] = "Segments";
    CampaignAuditColumns["CREATIVES"] = "Creatives";
    CampaignAuditColumns["EXT_METAS"] = "Ext Metas";
    CampaignAuditColumns["EXTERNAL_ID"] = "External ID";
    CampaignAuditColumns["STATUS"] = "Status";
    CampaignAuditColumns["ADVERTISER"] = "Advertiser";
    CampaignAuditColumns["CATEGORIES"] = "Categories";
})(CampaignAuditColumns || (CampaignAuditColumns = {}));
export var ClientDeviceNames;
(function (ClientDeviceNames) {
    ClientDeviceNames["CTV"] = "tv";
    ClientDeviceNames["DESKTOP"] = "desktop";
    ClientDeviceNames["MOBILE"] = "mobile";
    ClientDeviceNames["TABLET"] = "tablet";
})(ClientDeviceNames || (ClientDeviceNames = {}));
export var ContentPlatformType;
(function (ContentPlatformType) {
    ContentPlatformType["LINEAR"] = "linear";
    ContentPlatformType["OTT"] = "ott";
})(ContentPlatformType || (ContentPlatformType = {}));
export var DashboardTileId;
(function (DashboardTileId) {
    DashboardTileId["AGE_DISTRIBUTION"] = "age_distribution";
    DashboardTileId["CAMPAIGNS_TABLE"] = "campaigns_table";
    DashboardTileId["DAILY_BOOKED_IMPRESSIONS"] = "daily_booked_impressions";
    DashboardTileId["DAYPART_DISTRIBUTION"] = "daypart_distribution";
    DashboardTileId["DEVICE_DISTRIBUTION"] = "device_distribution";
    DashboardTileId["EDUCATION_DISTRIBUTION"] = "education_distribution";
    DashboardTileId["FREQUENCY_CHART"] = "frequency_chart";
    DashboardTileId["GENDER_DISTRIBUTION"] = "gender_distribution";
    DashboardTileId["HEATMAP"] = "heat_map";
    DashboardTileId["HOUSEHOLD_INCOME_DISTRIBUTION"] = "household_income_distribution";
    DashboardTileId["IMPRESSIONS_CHART"] = "impressions_chart";
    DashboardTileId["IMPRESSIONS_SUMMARY"] = "impressions_summary";
    DashboardTileId["PROVIDERS_TABLE"] = "providers_table";
    DashboardTileId["QUARTILES_CHART"] = "quartiles_chart";
    DashboardTileId["RACE_ETHNICITY_DISTRIBUTION"] = "race_ethnicity_distribution";
    DashboardTileId["UNIQUES_CHART"] = "uniques_chart";
    DashboardTileId["VCR_CHART"] = "vcr_chart";
    DashboardTileId["ZIPS_TABLE"] = "zips_table";
})(DashboardTileId || (DashboardTileId = {}));
export var DayOfTheWeek;
(function (DayOfTheWeek) {
    DayOfTheWeek["SUNDAY"] = "Sunday";
    DayOfTheWeek["MONDAY"] = "Monday";
    DayOfTheWeek["TUESDAY"] = "Tuesday";
    DayOfTheWeek["WEDNESDAY"] = "Wednesday";
    DayOfTheWeek["THURSDAY"] = "Thursday";
    DayOfTheWeek["FRIDAY"] = "Friday";
    DayOfTheWeek["SATURDAY"] = "Saturday";
})(DayOfTheWeek || (DayOfTheWeek = {}));
export var DeviceNames;
(function (DeviceNames) {
    DeviceNames["CTV"] = "Connected TV";
    DeviceNames["DESKTOP"] = "Desktop";
    DeviceNames["MOBILE"] = "Mobile";
    DeviceNames["TABLET"] = "Tablet";
})(DeviceNames || (DeviceNames = {}));
export var DurationTimeUnit;
(function (DurationTimeUnit) {
    DurationTimeUnit[DurationTimeUnit["MINUTE"] = 1] = "MINUTE";
    DurationTimeUnit[DurationTimeUnit["HOUR"] = 2] = "HOUR";
    DurationTimeUnit[DurationTimeUnit["DAILY"] = 3] = "DAILY";
    DurationTimeUnit[DurationTimeUnit["WEEK"] = 4] = "WEEK";
    DurationTimeUnit[DurationTimeUnit["MONTH"] = 5] = "MONTH";
    DurationTimeUnit[DurationTimeUnit["LIFETIME"] = 6] = "LIFETIME";
})(DurationTimeUnit || (DurationTimeUnit = {}));
export var ForecasterCreativeDurationNames;
(function (ForecasterCreativeDurationNames) {
    ForecasterCreativeDurationNames["SIX"] = "six";
    ForecasterCreativeDurationNames["FIFTEEN"] = "fifteen";
    ForecasterCreativeDurationNames["THIRTY"] = "thirty";
    ForecasterCreativeDurationNames["SIXTY"] = "sixty";
})(ForecasterCreativeDurationNames || (ForecasterCreativeDurationNames = {}));
/**
 * TODO: Research if these can be combined
 * ***************************************
 */
export var GeographicEntityTypes;
(function (GeographicEntityTypes) {
    GeographicEntityTypes["COUNTRY"] = "country";
    GeographicEntityTypes["STATE"] = "state";
    GeographicEntityTypes["DISTRICT"] = "district";
    GeographicEntityTypes["DMA"] = "dma";
    GeographicEntityTypes["ZIP_CODE"] = "zipCode";
})(GeographicEntityTypes || (GeographicEntityTypes = {}));
export var GeoTargetsKeys;
(function (GeoTargetsKeys) {
    GeoTargetsKeys["COUNTRY"] = "country";
    GeoTargetsKeys["STATE"] = "states";
    GeoTargetsKeys["DISTRICT"] = "districts";
    GeoTargetsKeys["DMA"] = "dmaCodes";
    GeoTargetsKeys["ZIP_CODE"] = "zipCodes";
})(GeoTargetsKeys || (GeoTargetsKeys = {}));
export var KnownApiKeys;
(function (KnownApiKeys) {
    KnownApiKeys["MAD_MASTER"] = "AIzaSyAfWJY5GRIt76F4hZ1EtF5wbPmdiYwygn4";
    KnownApiKeys["MAD_STAGING"] = "AIzaSyCLAkXoSgLkBXp1OzBHDOruGyIYDGV86gA";
    KnownApiKeys["MAD_QA"] = "AIzaSyClvAk_LFh9xoz62GmFKVzEOhmBY77hXYY";
    KnownApiKeys["MAD_HACK"] = "AIzaSyBCYd4KVOYVfXXPPckApN2P-2zU6MrZu0w";
    KnownApiKeys["MAD_DEMO"] = "AIzaSyAsDyBRdhVWJ7R0kSJ5_nAzGsPVcgPmpXY";
    KnownApiKeys["MADHIVE_TESTING"] = "AIzaSyCTtLIHaAZfdANYvuZ6ZKJnmcxLvWgdkuk";
    KnownApiKeys["MAD_DATA"] = "AIzaSyBdxlJyb_RbYadPA0Gpc2mhS34iLvPSPhU";
    KnownApiKeys["MAD_TALON"] = "AIzaSyDKs4IBw6sxTLK8en1VjqgQK4pnkkGKqDg";
})(KnownApiKeys || (KnownApiKeys = {}));
/**
 * Firebase App IDs unique to each project
 */
export var KnownAppIds;
(function (KnownAppIds) {
    KnownAppIds["MAD_MASTER"] = "1:681877790335:web:38ac959ca35263862d294a";
    KnownAppIds["MAD_STAGING"] = "1:410741125867:web:c6f419eedbb2542a";
    KnownAppIds["MAD_QA"] = "1:112562975508:web:7e5069c46e9cd6ce1a4801";
    KnownAppIds["MAD_HACK"] = "1:603316736725:web:a2efdb13129c4b1b";
    KnownAppIds["MAD_DEMO"] = "";
    KnownAppIds["MADHIVE_TESTING"] = "1:629681983625:web:4d70c0bb7359d508dff658";
    KnownAppIds["MAD_DATA"] = "";
    KnownAppIds["MAD_TALON"] = "1:554893307432:web:6c06a4f039329885458f1d";
})(KnownAppIds || (KnownAppIds = {}));
export var KnownOrganizationIds;
(function (KnownOrganizationIds) {
    KnownOrganizationIds["MADHIVE_DEFAULT"] = "0LDY6rsTok3wcPtS29QFr97Mtpw7";
    KnownOrganizationIds["MADHIVE_DEMO"] = "gHnCJV5lvJFBl2nnjOe6jgJjZJDA";
    KnownOrganizationIds["MADHIVE_MEDIA_GROUP"] = "wSjFJVSQzJFrvqzFc7Vulka9iYlt";
    KnownOrganizationIds["PREMION"] = "WUyi8DR5KszSZ1gqZQDgUDxyfHPu";
    KnownOrganizationIds["SCRIPPS"] = "oQRQL2OLWEc66UrAOPEFdE2FovnU";
    KnownOrganizationIds["BOBMILLS"] = "8VhQ9oAKez6i9wopOkNzpd4sLgqC";
    KnownOrganizationIds["MEDIAMATH"] = "yleAfTCPex1lbZ9hhoMHNmcr9HVe";
    KnownOrganizationIds["DIGILANT_ARCHIVED"] = "DCPZtWgO3bYiTKogyh14EVOseVAY";
    KnownOrganizationIds["FUTURESMEDIA"] = "euyEunq4rLSfQOCrdWz5RVH9TJ9P";
    KnownOrganizationIds["RUNNINGMATE"] = "vncgn7Q866rsQ5vwSd6odfviSazt";
    KnownOrganizationIds["BEACHFRONT"] = "GCfw4D887hnA6yPMrfUfXWW7FLjv";
    KnownOrganizationIds["BIG_SANDY_PROMOTIONS"] = "v6WDa1gZuncYwDqyhAed50OcfJQH";
    KnownOrganizationIds["ASSOCIATED_VOLUME_BUYERS"] = "yK8AMJdaccMfN9xf4jjVFzBm93up";
    KnownOrganizationIds["BTC_MEDIA"] = "ClEM2STNoYzBzmmExpbnQlXENWrC";
    KnownOrganizationIds["UAT_MEDIA"] = "yXcRhVeY9jsboRwHIsDN3eferfL6";
    KnownOrganizationIds["FOX"] = "KnfaABPhsYmEii2PRGC5HDNrHURa";
    KnownOrganizationIds["NEWSY"] = "Uf6rlJ0Eb3urafp7BEWwMeSY49pK";
    KnownOrganizationIds["RESIDENT_HOME"] = "QHN2qTtXMoheu71Bf3Z1QUqDoBEA";
    KnownOrganizationIds["VOICE_MEDIA"] = "GeoCmQovUBKoOtE5SYgNaArxCO1e";
    KnownOrganizationIds["LEGAL_ACTION_MEDIA"] = "xUuCHe79jqxtixHesVLvKNPf8kX0";
    KnownOrganizationIds["FOSTERS_LAB"] = "Hzi6if7u1IPXMO4xXzUomW1RyxLY";
    KnownOrganizationIds["MODUS_DIRECT"] = "02NfgA6Q647QYKR99XkLV87Ry1w8";
    KnownOrganizationIds["TEAM_LEWIS"] = "HOxg6p7Ll1e3BwgMHjZQLmIxgXgL";
    KnownOrganizationIds["KING"] = "5y74tkkH0InCs7mBurRutwoeV2dM";
    KnownOrganizationIds["DX_AGENCY"] = "krqWCZSNCeu6NTHD2pt39L9wLa55";
    KnownOrganizationIds["LINCOLN_DIGITAL_GROUP"] = "3SchfxBifMdrLvgX76S9bn2wMkAU";
    KnownOrganizationIds["NMPI"] = "GINako9MSgacdRCkfzBUXqjLNoEj";
    KnownOrganizationIds["PATHFINDER_INTERACTIVE"] = "fPDDmm6as42QStODHAwSWbMB3tqW";
    KnownOrganizationIds["CTV_MEDIA"] = "LL0pqfdpsrIInisoLdBd9isAR46W";
    KnownOrganizationIds["NEWS_PRESS_GAZETTE"] = "gDY22A1hSAhYTnftMogUSdHVMZux";
    KnownOrganizationIds["TALON"] = "gozSOpTNchKTqoXAKFVchYGTBPtN";
    KnownOrganizationIds["IHEART"] = "popErPc0MjpPi1i94r9M0gBNFrT8";
    KnownOrganizationIds["OMNICOM"] = "Htgjt6QJik3UaPNIYBLM5X6kgdy5";
    KnownOrganizationIds["CBC"] = "yTh0Oaq4wcnQPUTBdE1PtZSPuvZk";
    KnownOrganizationIds["GROUNDTRUTH"] = "elv3zCNrtro4DeHVXu9PWZ7tU5C3";
    KnownOrganizationIds["COX"] = "Zmw3FbjDKGVcZ5G9FtXhY1KhUHOw";
    KnownOrganizationIds["LOCALFACTOR"] = "3sAMttEljdlM6NMk1s19q3vU3gRa";
    KnownOrganizationIds["ALLENMEDIA"] = "kIz0oQ3nBDGL8SKTDA2dydIyPAjS";
    KnownOrganizationIds["GRIFFIN"] = "nK32adQRKBGiTpjaKRR1vAFTpYz0";
    KnownOrganizationIds["HEARST"] = "yfSFThMib3rB0bnnSdrrM7tvMSqF";
    KnownOrganizationIds["CAPITOL_BROADCASTING"] = "pDIhAm0B8uysPQPvUJr1rtVC6J9f";
    KnownOrganizationIds["MCAFEE"] = "IV75IbQ3Ex79JEMkiVmEt1zVk03r";
    KnownOrganizationIds["JUICE_MEDIA"] = "feZlPucGGkYyWYKf7gC1TKyB39Ri";
    KnownOrganizationIds["GOOD_KARMA"] = "envJdUpIsqw4eRUx2Sxc7ooKRLzg";
    KnownOrganizationIds["UNIVISION"] = "K36hImPZPvdCtBn6YDerF7Jf4Ox8";
    KnownOrganizationIds["GYDIGITAL"] = "kd9Xr3zOe9lTPLQgXfhyza6IFEXH";
    KnownOrganizationIds["MADHIVE_INTEGRATION_TESTING_ORG"] = "TOuUfbFigOu6pQri5um8yC9KZf8H";
    KnownOrganizationIds["KATZ_DIGITAL"] = "Lk0ryApLagZo5gpifZcjAKab0xT2";
    KnownOrganizationIds["SPARKLIGHT"] = "EHLg5z1MFrtKA3RSbxwj0zlP8fyV";
    KnownOrganizationIds["HP"] = "FKFkOkuTNa3gR8abGDC9yaJqwJpO";
    KnownOrganizationIds["NOM"] = "1o47Pd8RSYbaoN8CitEMAga3WZLs";
    KnownOrganizationIds["BEN_HER"] = "4IZ8Lymc7mpBUvlHM0QjulL99wmj";
    KnownOrganizationIds["THE_REAL_REAL"] = "f0sRH9vSKC5HteQrgLHFpbJWqg1A";
    KnownOrganizationIds["BRANT_MEDIA"] = "4SG9pjc5xmqOD5fPzaLbB4zErFDe";
    KnownOrganizationIds["GAMUT"] = "NYwvzjoPe27EINA0xTYmptehKkSF";
    KnownOrganizationIds["EVERGREEN"] = "LykdvSgtQqhBk9Z0XKKEMt6ClzD1";
    KnownOrganizationIds["BAHAKEL"] = "BX92sxdiIZ6Jc7AhRdgR6Cg2ps6g";
    KnownOrganizationIds["ANALYTICS_OWL"] = "IJJMEcgaGXio3PDcRqwVXUe1TkOo";
    KnownOrganizationIds["DLM_AGENCY"] = "okfybZ53Nsf7QMDy2IHX4PdNPrSE";
    KnownOrganizationIds["MEDICX"] = "Mbqxuz4d322z8hdAkUSwRorojbAH";
    KnownOrganizationIds["TOWNSQUARE"] = "kBESR6YRAWrNSjkGOj8oBgbAMIBi";
    KnownOrganizationIds["DIGITAL_ANALYTICS_PRO"] = "EhjFKK03MSVLPJFA2DWud0w83OS1";
    KnownOrganizationIds["BLOCK_COMMUNICATIONS"] = "OhX5eFRPRNIMr0pTrZm8uiKG9l2Y";
    KnownOrganizationIds["TURNER_SPORTS"] = "BYKQ4zandCay6nflSxxno4kOxF0N";
    KnownOrganizationIds["HOMESLICE_GROUP"] = "FQWCsxhyPhz0zT3oxX9XQNcVWu8o";
    KnownOrganizationIds["MONDAY"] = "9b6SeNrdZe3TPgpFOzI40gpsWi8b";
    KnownOrganizationIds["WIN_DIGITAL"] = "wHgVUW95xsrKc5CGYxqQpoOvuOzX";
    KnownOrganizationIds["CHARLTON_MARKETING"] = "zYYeLiGHevn0eYSReWh9vjQtqUFs";
    KnownOrganizationIds["TALMONT"] = "E43dBbgXdxNOEKOxkPMU4NYStHCJ";
    KnownOrganizationIds["FIVE_STAR_MARKETING_ADVISORS"] = "iUtqJ6NYGXwhFVnOpHrE4JElIhhF";
    KnownOrganizationIds["EVIDNT"] = "ZTXybcyBTqFZTHg0pbfp2Mj9x0C8";
    KnownOrganizationIds["DISH_SLING"] = "9PKzAD4YPYS4lFCivT3UrsSqSNvW";
    KnownOrganizationIds["T_MOBILE"] = "Fb5QUX3CC70kR4O9HBN3cF4pL242";
    KnownOrganizationIds["AI_DIGITAL"] = "bSae2cldxK8ZONbyR7piYiZ5oVMH";
    KnownOrganizationIds["TRIP_ADVISOR"] = "bLl7qBbXb2xc3ohGxreWABRjwgfp";
    KnownOrganizationIds["LIFTOFF"] = "CSnfGpe0eXFLB6k7Kk2w0N8ma5BK";
    KnownOrganizationIds["MiQ"] = "HuNbYsaiG8drRtWQOAXRUICJauXY";
    KnownOrganizationIds["OMG"] = "rUwuB8nmH1so0RDSxvsx8n6i7Tnt";
    KnownOrganizationIds["OTTERA"] = "ctyszISfXjdiVRp43mJMyWj95JfE";
    KnownOrganizationIds["VOGLIO"] = "cX1V4bgobjzcEHbzXHqx1fp0LPvh";
    KnownOrganizationIds["ARKADAS_GROUP"] = "1eW9mFjO1kfGzzjG9XwaIKHivrs3";
    KnownOrganizationIds["SAKS_OFF_FIFTH"] = "HVq3Zp0p0LbH6Ds8xkEjbok4rmZO";
    KnownOrganizationIds["BUCKEYE_BROADBAND"] = "NGEehc6JcZHP2ze60T46bUvcII80";
    KnownOrganizationIds["LIFEBRANDS_D2C"] = "pSvOtBJWuad3xH87BlM1cswe93Jc";
    KnownOrganizationIds["KILL_YOUR_COMPETITION"] = "FjJetNS58F36YDRY5cnHYeqzLHKz";
    KnownOrganizationIds["SONOBI"] = "4GXE7Pbsv1iyJEBl9pO3UuKPx1AH";
    KnownOrganizationIds["BATEMAN_COLLECTIVE"] = "o9IFzg4yVXyzZyr3tlaAyIIZPBA4";
    KnownOrganizationIds["FEDERATED_DIGITAL_SOLUTIONS"] = "VarRZyjWWxu7oLkaaLTZmBnifCFu";
    KnownOrganizationIds["KR8_VENTURES"] = "oyaH2HNXF6fTYyPGeLmPkpR4asiU";
    KnownOrganizationIds["ESSENTIALISM_ADVERTISING"] = "KM54LBScwYRW1Y3dMSMw9zmkEoKl";
    KnownOrganizationIds["RED_VENTURES"] = "rwpvAHUCjERFqNbqAmT85Vzx7w4G";
    KnownOrganizationIds["HUDDLED_MASSES"] = "uGlpSYl5tn43Wzm8UsX13IZVeyUu";
    KnownOrganizationIds["AUTOMATED_TESTING"] = "cBH3SdCMtagB5uG6wiKkze5XAclJ";
    KnownOrganizationIds["GREEN_AND_WOOD_MEDIA"] = "LZDe1FCGXZIR338PUS5Xt4pEgz1e";
    KnownOrganizationIds["TASTEMADE"] = "uOtZL71KMIpubgMFtaQAKmiyTPEn";
    KnownOrganizationIds["DIGILANT"] = "Ddo8mxzkOJK15rJ6hGHBrTXtWMXV";
    KnownOrganizationIds["CHAMPIONSHIP_ADVERTISING"] = "9BSxAdrcn29vvCbHwpEIi7vsQe8f";
    KnownOrganizationIds["CTV_BUYER"] = "WpLJmLrdF4yOLUEtTXMfqXBASi2Z";
    KnownOrganizationIds["LEV_LANE"] = "Pt3csz4p9PjZYWqQFCTI9j23hwgz";
    KnownOrganizationIds["THE_DIGITAL_RUCKUS"] = "eZoxyTWExg4YF5sN9Sav7DCzgflW";
    KnownOrganizationIds["BRAVE"] = "UHSHTdDBGclBvhWSBb12c1wsLkO0";
    KnownOrganizationIds["VIDEOHEROES"] = "ZyNjc5V5TdBSQvOxpxxApyPsSbTl";
    KnownOrganizationIds["ENTHUSIAST_GAMING"] = "8NdrCwGZMG1jUyMeKQ8VRp23yByp";
    KnownOrganizationIds["CBS"] = "t8Wl0YgWWav8tbllTIlJoUvL4Eti";
    KnownOrganizationIds["NIMBUS99"] = "MYIactxZzo2e834lRQStFmZxCeEL";
    KnownOrganizationIds["CONTINUUM_MEDIA"] = "Mkf8fm4GIP4VqbJIBEmGWh9LMaRU";
    KnownOrganizationIds["NEXSTAR"] = "N6WSKUog3hq2PMHh4sTsmiADteE5";
    KnownOrganizationIds["BLAM_RETAIL"] = "1BWOVAS20ZndoicV5lm5TxVyZLsb";
    KnownOrganizationIds["VICA_AI"] = "nyeD0YrXLsy9XGh0b3OOWLYBHXnZ";
    KnownOrganizationIds["NIGHTLIFE_TELEVISION"] = "9bZ9uvs9DMb6U2W16gSZfkuq6DfL";
    KnownOrganizationIds["JRR_ADVERTISING"] = "Ec8txGaogLMDf4PuaLIfYiBoo3SY";
    KnownOrganizationIds["ESTRELLA_MEDIA"] = "N0uIydAbHNO2KwsayNi8CDt82SvI";
    KnownOrganizationIds["PERFORMCB"] = "mFcy71xQk6s7XuqAgF6TFga9R76h";
    KnownOrganizationIds["NEW_ROAD_ADVERTISING"] = "YjoDaetqKlt2YPUtP69ZxdC7hNrY";
    KnownOrganizationIds["QUICKTURN_MEDIA"] = "oRQ8AwxCueYcEc97L0KJMHMi9KFv";
    KnownOrganizationIds["CONNECTADTV"] = "B4N9uoplTf79C2o6b0iM7w5AO57U";
    KnownOrganizationIds["NRG_MEDIA"] = "2mbwLy0M58eTjU30WNhcslFXc0om";
    KnownOrganizationIds["LILLY_BROADCASTING"] = "3l20RtK0ctEvt174BcPdjaTvJqQS";
})(KnownOrganizationIds || (KnownOrganizationIds = {}));
export var KnownOrganizationNames;
(function (KnownOrganizationNames) {
    KnownOrganizationNames["MADHIVE_DEFAULT"] = "madhive";
    KnownOrganizationNames["MADHIVE_DEMO"] = "madhivedemo";
    KnownOrganizationNames["MADHIVE_MEDIA_GROUP"] = "madhive-media-group";
    KnownOrganizationNames["PREMION"] = "premion";
    KnownOrganizationNames["SCRIPPS"] = "scripps";
    KnownOrganizationNames["BOBMILLS"] = "bobmills";
    KnownOrganizationNames["RUNNINGMATE"] = "runningmate";
    KnownOrganizationNames["BEACHFRONT"] = "beachfront";
    KnownOrganizationNames["FUTURESMEDIA"] = "futuresmedia";
    KnownOrganizationNames["MEDIAMATH"] = "mediamath";
    KnownOrganizationNames["DIGILANT_ARCHIVED"] = "digilant-archived";
    KnownOrganizationNames["BIG_SANDY_PROMOTIONS"] = "big-sandy-promotions";
    KnownOrganizationNames["ASSOCIATED_VOLUME_BUYERS"] = "associated-volume-buyers";
    KnownOrganizationNames["BTC_MEDIA"] = "btc-media";
    KnownOrganizationNames["UAT_MEDIA"] = "uat-media";
    KnownOrganizationNames["FOX"] = "fox";
    KnownOrganizationNames["NEWSY"] = "newsy";
    KnownOrganizationNames["RESIDENT_HOME"] = "resident-home";
    KnownOrganizationNames["VOICE_MEDIA"] = "voice-media";
    KnownOrganizationNames["LEGAL_ACTION_MEDIA"] = "legal-action-media";
    KnownOrganizationNames["FOSTERS_LAB"] = "fosters-lab";
    KnownOrganizationNames["MODUS_DIRECT"] = "modus-direct";
    KnownOrganizationNames["TEAM_LEWIS"] = "team-lewis";
    KnownOrganizationNames["KING"] = "king";
    KnownOrganizationNames["DX_AGENCY"] = "dx-agency";
    KnownOrganizationNames["LINCOLN_DIGITAL_GROUP"] = "lincoln-digital-group";
    KnownOrganizationNames["NMPI"] = "nmpi";
    KnownOrganizationNames["PATHFINDER_INTERACTIVE"] = "pathfinder-interactive";
    KnownOrganizationNames["CTV_MEDIA"] = "ctv-media";
    KnownOrganizationNames["NEWS_PRESS_GAZETTE"] = "news-press-gazette";
    KnownOrganizationNames["TALON"] = "talon";
    KnownOrganizationNames["IHEART"] = "iheart-radio";
    KnownOrganizationNames["OMNICOM"] = "omnicom";
    KnownOrganizationNames["CBC"] = "cbc";
    KnownOrganizationNames["GROUNDTRUTH"] = "groundtruth";
    KnownOrganizationNames["COX"] = "cox";
    KnownOrganizationNames["LOCALFACTOR"] = "localfactor";
    KnownOrganizationNames["ALLENMEDIA"] = "allenmedia";
    KnownOrganizationNames["GRIFFIN"] = "griffin";
    KnownOrganizationNames["HEARST"] = "hearst";
    KnownOrganizationNames["CAPITOL_BROADCASTING"] = "capitol-broadcasting";
    KnownOrganizationNames["MCAFEE"] = "mcafee";
    KnownOrganizationNames["JUICE_MEDIA"] = "juice-media";
    KnownOrganizationNames["GOOD_KARMA"] = "good-karma";
    KnownOrganizationNames["UNIVISION"] = "univision";
    KnownOrganizationNames["GYDIGITAL"] = "gydigital";
    KnownOrganizationNames["MADHIVE_INTEGRATION_TESTING_ORG"] = "madhive-integration-testing-organization";
    KnownOrganizationNames["KATZ_DIGITAL"] = "katz-digital";
    KnownOrganizationNames["SPARKLIGHT"] = "sparklight";
    KnownOrganizationNames["HP"] = "hp";
    KnownOrganizationNames["NOM"] = "nom";
    KnownOrganizationNames["BEN_HER"] = "ben-her";
    KnownOrganizationNames["THE_REAL_REAL"] = "the-real-real";
    KnownOrganizationNames["BRANT_MEDIA"] = "brant-media";
    KnownOrganizationNames["GAMUT"] = "gamut";
    KnownOrganizationNames["EVERGREEN"] = "evergreen";
    KnownOrganizationNames["BAHAKEL"] = "bahakel";
    KnownOrganizationNames["ANALYTICS_OWL"] = "analytics-owl";
    KnownOrganizationNames["DLM_AGENCY"] = "dlm-agency";
    KnownOrganizationNames["MEDICX"] = "medicx";
    KnownOrganizationNames["TOWNSQUARE"] = "townsquare";
    KnownOrganizationNames["DIGITAL_ANALYTICS_PRO"] = "digital-analytics-pro";
    KnownOrganizationNames["TURNER_SPORTS"] = "turner-sports";
    KnownOrganizationNames["BLOCK_COMMUNICATIONS"] = "block-communications";
    KnownOrganizationNames["HOMESLICE_GROUP"] = "homeslice-group";
    KnownOrganizationNames["MONDAY"] = "monday";
    KnownOrganizationNames["WIN_DIGITAL"] = "win-digital";
    KnownOrganizationNames["CHARLTON_MARKETING"] = "charlton-marketing";
    KnownOrganizationNames["TALMONT"] = "talmont";
    KnownOrganizationNames["FIVE_STAR_MARKETING_ADVISORS"] = "five-star-marketing-advisors";
    KnownOrganizationNames["EVIDNT"] = "evidnt";
    KnownOrganizationNames["DISH_SLING"] = "dish-sling";
    KnownOrganizationNames["T_MOBILE"] = "t-mobile";
    KnownOrganizationNames["AI_DIGITAL"] = "ai-digital";
    KnownOrganizationNames["TRIP_ADVISOR"] = "trip-advisor";
    KnownOrganizationNames["LIFTOFF"] = "liftoff";
    KnownOrganizationNames["MiQ"] = "miq";
    KnownOrganizationNames["OMG"] = "omg";
    KnownOrganizationNames["OTTERA"] = "ottera";
    KnownOrganizationNames["VOGLIO"] = "vogliomarketing";
    KnownOrganizationNames["ARKADAS_GROUP"] = "arkadas-group";
    KnownOrganizationNames["SAKS_OFF_FIFTH"] = "saks-off-fifth";
    KnownOrganizationNames["BUCKEYE_BROADBAND"] = "buckeye-broadband";
    KnownOrganizationNames["LIFEBRANDS_D2C"] = "lifebrands-d2c";
    KnownOrganizationNames["KILL_YOUR_COMPETITION"] = "kill-your-competition";
    KnownOrganizationNames["SONOBI"] = "sonobi";
    KnownOrganizationNames["BATEMAN_COLLECTIVE"] = "bateman-collective";
    KnownOrganizationNames["FEDERATED_DIGITAL_SOLUTIONS"] = "federated-digital-solutions";
    KnownOrganizationNames["KR8_VENTURES"] = "kr8-ventures";
    KnownOrganizationNames["ESSENTIALISM_ADVERTISING"] = "essentialism-advertising";
    KnownOrganizationNames["RED_VENTURES"] = "red-ventures";
    KnownOrganizationNames["HUDDLED_MASSES"] = "huddled-masses";
    KnownOrganizationNames["AUTOMATED_TESTING"] = "automated-testing";
    KnownOrganizationNames["GREEN_AND_WOOD_MEDIA"] = "green-and-wood-media";
    KnownOrganizationNames["TASTEMADE"] = "tastemade";
    KnownOrganizationNames["DIGILANT"] = "digilant";
    KnownOrganizationNames["CHAMPIONSHIP_ADVERTISING"] = "championship-advertising";
    KnownOrganizationNames["CTV_BUYER"] = "ctv-buyer";
    KnownOrganizationNames["LEV_LANE"] = "lev-lane";
    KnownOrganizationNames["THE_DIGITAL_RUCKUS"] = "the-digital-ruckus";
    KnownOrganizationNames["BRAVE"] = "brave";
    KnownOrganizationNames["VIDEOHEROES"] = "videoheroes";
    KnownOrganizationNames["ENTHUSIAST_GAMING"] = "enthusiast-gaming";
    KnownOrganizationNames["CBS"] = "cbs";
    KnownOrganizationNames["NIMBUS99"] = "nimbus99";
    KnownOrganizationNames["CONTINUUM_MEDIA"] = "continuum-media";
    KnownOrganizationNames["NEXSTAR"] = "nexstar";
    KnownOrganizationNames["BLAM_RETAIL"] = "blam-retail";
    KnownOrganizationNames["VICA_AI"] = "vica-ai";
    KnownOrganizationNames["NIGHTLIFE_TELEVISION"] = "nightlife-television";
    KnownOrganizationNames["JRR_ADVERTISING"] = "jrr-advertising";
    KnownOrganizationNames["ESTRELLA_MEDIA"] = "estrella-media";
    KnownOrganizationNames["PERFORMCB"] = "performcb";
    KnownOrganizationNames["NEW_ROAD_ADVERTISING"] = "new-road-advertising";
    KnownOrganizationNames["QUICKTURN_MEDIA"] = "quickturn-media";
    KnownOrganizationNames["CONNECTADTV"] = "connectadtv";
    KnownOrganizationNames["NRG_MEDIA"] = "nrg-media";
    KnownOrganizationNames["LILLY_BROADCASTING"] = "lilly-broadcasting";
})(KnownOrganizationNames || (KnownOrganizationNames = {}));
/**
 * Firebase Analytics measurement IDs unique to each project
 */
export var KnownMeasurementIds;
(function (KnownMeasurementIds) {
    KnownMeasurementIds["MAD_MASTER"] = "G-BCXMQSWMWZ";
    KnownMeasurementIds["MAD_STAGING"] = "G-QDX9CTFBV4";
    KnownMeasurementIds["MAD_QA"] = "G-MYTKKYZLC7";
    KnownMeasurementIds["MAD_HACK"] = "G-D705QK4DQE";
    KnownMeasurementIds["MAD_DEMO"] = "";
    KnownMeasurementIds["MADHIVE_TESTING"] = "";
    KnownMeasurementIds["MAD_DATA"] = "";
    KnownMeasurementIds["MAD_TALON"] = "G-RJZHX48QB2";
})(KnownMeasurementIds || (KnownMeasurementIds = {}));
export var KnownProjectIds;
(function (KnownProjectIds) {
    KnownProjectIds["MAD_DATA"] = "mad-data";
    KnownProjectIds["MAD_MASTER"] = "mad-master";
    KnownProjectIds["MAD_STAGING"] = "mad-staging";
    KnownProjectIds["MAD_QA"] = "mad-qa";
    KnownProjectIds["MAD_HACK"] = "mad-hack";
    KnownProjectIds["MAD_DEMO"] = "mad-demo-253314";
    KnownProjectIds["MADHIVE_TESTING"] = "madhive-testing";
    KnownProjectIds["MAD_TALON"] = "mad-talon";
})(KnownProjectIds || (KnownProjectIds = {}));
export var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator["INCLUDE"] = "include";
    LogicalOperator["EXCLUDE"] = "exclude";
})(LogicalOperator || (LogicalOperator = {}));
export var ManageCampaignColumn;
(function (ManageCampaignColumn) {
    ManageCampaignColumn["NAME"] = "name";
    ManageCampaignColumn["ASSOCIATED_CAMPAIGN"] = "associated-campaign";
    ManageCampaignColumn["STATUS"] = "status";
    ManageCampaignColumn["BE_STATUS"] = "backend-status";
    ManageCampaignColumn["SET_LIVE"] = "set-live";
    ManageCampaignColumn["LINE_ITEMS_COUNT"] = "line-items-count";
    ManageCampaignColumn["CREATIVES_COUNT"] = "creatives-count";
    ManageCampaignColumn["ASSOCIATED_SCENARIO"] = "associated-scenario";
    ManageCampaignColumn["START_DATE"] = "start-date";
    ManageCampaignColumn["END_DATE"] = "end-date";
    ManageCampaignColumn["MADHIVE_ID"] = "madhive-id";
    ManageCampaignColumn["OMS_ID"] = "oms-id";
    ManageCampaignColumn["EXTERNAL_ID"] = "external-id";
    ManageCampaignColumn["GOAL"] = "goal";
    ManageCampaignColumn["BOOKED_IMPRESSIONS"] = "booked-impressions";
    ManageCampaignColumn["LAST_UPDATED"] = "last-updated";
    ManageCampaignColumn["UPDATED_BY"] = "updated-by";
    ManageCampaignColumn["CATEGORY"] = "category";
    ManageCampaignColumn["MEDIA_TYPE"] = "media-type";
    ManageCampaignColumn["PRODUCT_CODE"] = "product-code";
    ManageCampaignColumn["ESTIMATE_CODE"] = "estimate-code";
    ManageCampaignColumn["PREMION_CLIENT_CODE"] = "premion-client-code";
    ManageCampaignColumn["PREMION_CLIENT_ESTIMATE_CODE"] = "premion-client-estimate-code";
    ManageCampaignColumn["PREMION_RFP_DETAILS"] = "premion-rfp-details-name";
    ManageCampaignColumn["PREMION_RFP_NAME"] = "premion-rfp-name";
    ManageCampaignColumn["PREMION_REVENUE_TYPE"] = "premion-revenue-type";
    ManageCampaignColumn["PREMION_PLACEMENTS_IO_ID"] = "premion-placement-io-id";
    ManageCampaignColumn["PREMION_PLACEMENTS_IO_GROUP_ID"] = "premion-placement-io-group-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_STATUS"] = "scripps-adbook-status";
    ManageCampaignColumn["SCRIPPS_ADBOOK_MARKET"] = "scripps-adbook-market";
    ManageCampaignColumn["SCRIPPS_ADBOOK_CLIENT_NAME"] = "scripps-adbook-client-name";
    ManageCampaignColumn["SCRIPPS_ADBOOK_EXTERNAL_ADVERTISER_ID"] = "scripps-adbook-external-advertiser-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_ADVERTISER"] = "scripps-adbook-advertiser";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PACKAGE_ID"] = "scripps-adbook-package";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PACKAGE_NAME"] = "scripps-adbook-package-name";
    ManageCampaignColumn["SCRIPPS_WIDE_ORBIT_ID"] = "scripps-wide-orbit-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_SEGMENT_NOTE"] = "scripps-adbook-segment-notes";
    ManageCampaignColumn["SCRIPPS_ADBOOK_POSTAL_CODES"] = "scripps-adbook-postal-codes";
    ManageCampaignColumn["SCRIPPS_ADBOOK_GEO"] = "scripps-adbook-geo";
    ManageCampaignColumn["SCRIPPS_ADBOOK_GEO_IDS"] = "scripps-adbook-geo-ids";
    ManageCampaignColumn["SCRIPPS_ADBOOK_AGENCY_ID"] = "scripps-adbook-agency-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_CAMPAIGN_ID"] = "scripps-adbook-campaign-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_STATION"] = "scripps-adbook-station";
    ManageCampaignColumn["SCRIPPS_ADBOOK_STATION_ID"] = "scripps-adbook-station-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_DROP_ID"] = "scripps-adbook-drop-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_DROP_STATUS"] = "scripps-adbook-line-item-status";
    ManageCampaignColumn["SCRIPPS_ID"] = "scripps-adbook-scripps-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_ADVERTISER_ID"] = "scripps-adbook-advertiser-id";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PRICE"] = "scripps-adbook-price";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PRICE_TYPE"] = "scripps-adbook-price-type";
    ManageCampaignColumn["SCRIPPS_ADBOOK_GROSS_PRICE"] = "scripps-adbook-gross-price";
    ManageCampaignColumn["SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE"] = "scripps-adbook-adbook-stn-consumer-segment";
    ManageCampaignColumn["SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE"] = "scripps-adbook-adbook-stn-demo-audience";
    ManageCampaignColumn["SCRIPPS_ADBOOK_CUSTOM_SEGMENT"] = "scripps-adbook-custom-segment";
    ManageCampaignColumn["SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES"] = "scripps-adbook-custom-segment-notes";
    ManageCampaignColumn["SCRIPPS_OCTANE_PRODUCT"] = "scripps-field-adbook-octane-product";
    ManageCampaignColumn["SCRIPPS_ADBOOK_REVISION"] = "scripps-adbook-revision";
    ManageCampaignColumn["SCRIPPS_ADBOOK_LAST_UPDATED"] = "scripps-adbook-last-updated";
    ManageCampaignColumn["SCRIPPS_ADBOOK_WHITE_LIST"] = "scripps-adbook-whitelist";
    ManageCampaignColumn["SCRIPPS_ADBOOK_WHITE_LIST_NAME"] = "scripps-adbook-whitelist-name";
    ManageCampaignColumn["SCRIPPS_ADBOOK_AGENCY_NAME"] = "scripps-adbook-agency-name";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH"] = "scripps-adbook-package-position-path";
    ManageCampaignColumn["SCRIPPS_ADBOOK_POSITION_NAME"] = "scripps-adbook-position-name";
    ManageCampaignColumn["SCRIPPS_OCTANE_ADVERTISER_DOMAIN"] = "scripps-octane-advertiser-domain";
    ManageCampaignColumn["SCRIPPS_ADBOOK_PACKAGE_LEVEL"] = "scripps-adbook-package-level";
    ManageCampaignColumn["SCRIPPS_ADBOOK_LAST_CHANGE_BY"] = "scripps-adbook-changed-by";
    ManageCampaignColumn["SCRIPPS_ADBOOK_CONTRACT_DATE"] = "scripps-adbook-contract-date";
    ManageCampaignColumn["FOX_WIDEORBIT_LINE_ID"] = "fox-wideorbit-line-id";
    ManageCampaignColumn["GROUNDTRUTH_BRANDS"] = "groundtruth-brands";
    ManageCampaignColumn["GROUNDTRUTH_LOCATION_GROUPS"] = "groundtruth-location-groups";
    // DOUBLE_VERIFY = "double-verify",
    ManageCampaignColumn["SALESFORCE_INSERTION_ORDER_ID"] = "salesforce-insertion-order-id";
    ManageCampaignColumn["WIDEORBIT_ACCOUNT_EXECUTIVE"] = "wideorbit-account-executive";
    ManageCampaignColumn["WIDEORBIT_ADVERTISER_NAME"] = "wideorbit-advertiser-name";
    ManageCampaignColumn["WIDEORBIT_AGENCY_COMMISSION"] = "wideorbit-agency-commission";
    ManageCampaignColumn["WIDEORBIT_AGENCY_NAME"] = "wideorbit-agency-name";
    ManageCampaignColumn["WIDEORBIT_AGENCY_PRODUCT_CODE"] = "wideorbit-agency-product-code";
    ManageCampaignColumn["WIDEORBIT_BILLING_ADDRESS_CITY"] = "wideorbit-billing-address-city";
    ManageCampaignColumn["WIDEORBIT_BILLING_ADDRESS_STATE"] = "wideorbit-billing-address-state";
    ManageCampaignColumn["WIDEORBIT_BILLING_ADDRESS_STREET"] = "wideorbit-billing-address-street";
    ManageCampaignColumn["WIDEORBIT_BILLING_ADDRESS_ZIP"] = "wideorbit-billing-address-zip";
    ManageCampaignColumn["WIDEORBIT_BILLING_CALENDAR"] = "wideorbit-billing-calendar";
    ManageCampaignColumn["WIDEORBIT_BILLING_CYCLE"] = "wideorbit-billing-cycle";
    ManageCampaignColumn["WIDEORBIT_CHANNEL"] = "wideorbit-channel";
    ManageCampaignColumn["WIDEORBIT_CLIENT_CODE"] = "wideorbit-client-code";
    ManageCampaignColumn["WIDEORBIT_DEMO_GROUP"] = "wideorbit-demo-group";
    ManageCampaignColumn["WIDEORBIT_DEMO_LOWER_AGE"] = "wideorbit-demo-lower-age";
    ManageCampaignColumn["WIDEORBIT_DEMO_UPPER_AGE"] = "wideorbit-demo-upper-age";
    ManageCampaignColumn["WIDEORBIT_ESTIMATE_NUMBER"] = "wideorbit-estimate-number";
    ManageCampaignColumn["WIDEORBIT_IS_LINE_DELETED"] = "wideorbit-is-line-deleted";
    ManageCampaignColumn["WIDEORBIT_IS_ORDER_DELETED"] = "wideorbit-is-order-deleted";
    ManageCampaignColumn["WIDEORBIT_LAST_PROCESSED_DATE"] = "wideorbit-last-processed-date";
    ManageCampaignColumn["WIDEORBIT_LINE_END_DATE"] = "wideorbit-line-end-date";
    ManageCampaignColumn["WIDEORBIT_LINE_IMPRESSIONS"] = "wideorbit-line-impressions";
    ManageCampaignColumn["WIDEORBIT_LINE_INTERNAL_COMMENTS"] = "wideorbit-line-internal-comments";
    ManageCampaignColumn["WIDEORBIT_LINE_NUMBER"] = "wideorbit-line-number";
    ManageCampaignColumn["WIDEORBIT_LINE_PRODUCT_DESCRIPTION"] = "wideorbit-line-product-description";
    ManageCampaignColumn["WIDEORBIT_LINE_START_DATE"] = "wideorbit-line-start-date";
    ManageCampaignColumn["WIDEORBIT_NUMBER_OF_TIMES_PROCESSED"] = "wideorbit-number-of-times-processed";
    ManageCampaignColumn["WIDEORBIT_ORDER_END_DATE"] = "wideorbit-order-end-date";
    ManageCampaignColumn["WIDEORBIT_ORDER_IS_COOP"] = "wideorbit-order-is-coop";
    ManageCampaignColumn["WIDEORBIT_ORDER_NUMBER"] = "wideorbit-order-number";
    ManageCampaignColumn["WIDEORBIT_ORDER_START_DATE"] = "wideorbit-order-start-date";
    ManageCampaignColumn["WIDEORBIT_PRODUCT_DESCRIPTION"] = "wideorbit-product-description";
    ManageCampaignColumn["WIDEORBIT_PROPERTY"] = "wideorbit-property";
    ManageCampaignColumn["WIDEORBIT_SALES_OFFICE"] = "wideorbit-sales-office";
    ManageCampaignColumn["WIDEORBIT_SALES_REGION"] = "wideorbit-sales-region";
    ManageCampaignColumn["WIDEORBIT_UNIT_CODE_DISPLAY"] = "wideorbit-unit-code-display";
})(ManageCampaignColumn || (ManageCampaignColumn = {}));
export var NewNewYorkHumanReadableStatusCode;
(function (NewNewYorkHumanReadableStatusCode) {
    NewNewYorkHumanReadableStatusCode["OK"] = "OK";
    NewNewYorkHumanReadableStatusCode["ERROR"] = "ERROR";
})(NewNewYorkHumanReadableStatusCode || (NewNewYorkHumanReadableStatusCode = {}));
// This information as formerly encoded in line-by-line comments
// alongside enum ObjType. However, when the new org bot script
// parses this file, it does not parse thesed inline comments,
// so we need to encode this information here, in a way that
// will be preserved.
//
// Reference: https://www.asciitable.com/
export var AsciiType;
(function (AsciiType) {
    AsciiType[AsciiType["A"] = 65] = "A";
    AsciiType[AsciiType["B"] = 66] = "B";
    AsciiType[AsciiType["C"] = 67] = "C";
    AsciiType[AsciiType["D"] = 68] = "D";
    AsciiType[AsciiType["E"] = 69] = "E";
    AsciiType[AsciiType["F"] = 70] = "F";
    AsciiType[AsciiType["G"] = 71] = "G";
    AsciiType[AsciiType["H"] = 72] = "H";
    AsciiType[AsciiType["I"] = 73] = "I";
    AsciiType[AsciiType["J"] = 74] = "J";
    AsciiType[AsciiType["K"] = 75] = "K";
    AsciiType[AsciiType["L"] = 76] = "L";
    AsciiType[AsciiType["M"] = 77] = "M";
    AsciiType[AsciiType["N"] = 78] = "N";
    AsciiType[AsciiType["O"] = 79] = "O";
    AsciiType[AsciiType["P"] = 80] = "P";
    AsciiType[AsciiType["Q"] = 81] = "Q";
    AsciiType[AsciiType["R"] = 82] = "R";
    AsciiType[AsciiType["S"] = 83] = "S";
    AsciiType[AsciiType["T"] = 84] = "T";
    AsciiType[AsciiType["U"] = 85] = "U";
    AsciiType[AsciiType["V"] = 86] = "V";
    AsciiType[AsciiType["W"] = 87] = "W";
    AsciiType[AsciiType["X"] = 88] = "X";
    AsciiType[AsciiType["Y"] = 89] = "Y";
    AsciiType[AsciiType["Z"] = 90] = "Z";
    AsciiType[AsciiType["a"] = 97] = "a";
    AsciiType[AsciiType["b"] = 98] = "b";
    AsciiType[AsciiType["c"] = 99] = "c";
    AsciiType[AsciiType["_"] = 95] = "_";
    AsciiType[AsciiType["s"] = 115] = "s";
})(AsciiType || (AsciiType = {}));
export var ObjType;
(function (ObjType) {
    ObjType[ObjType["UNKNOWN"] = 0] = "UNKNOWN";
    ObjType[ObjType["AGENCY"] = 65] = "AGENCY";
    ObjType[ObjType["BRAND"] = 66] = "BRAND";
    ObjType[ObjType["CAMP"] = 67] = "CAMP";
    ObjType[ObjType["BRAND_GROUP"] = 68] = "BRAND_GROUP";
    ObjType[ObjType["AGENCY_GROUP"] = 69] = "AGENCY_GROUP";
    ObjType[ObjType["WLIST_OVER"] = 70] = "WLIST_OVER";
    ObjType[ObjType["SEG"] = 71] = "SEG";
    ObjType[ObjType["TAG"] = 72] = "TAG";
    ObjType[ObjType["IO"] = 73] = "IO";
    ObjType[ObjType["VAST"] = 74] = "VAST";
    ObjType[ObjType["PROD"] = 75] = "PROD";
    ObjType[ObjType["LI_FLIGHT"] = 76] = "LI_FLIGHT";
    ObjType[ObjType["LI"] = 77] = "LI";
    ObjType[ObjType["INST"] = 78] = "INST";
    ObjType[ObjType["ORG"] = 79] = "ORG";
    ObjType[ObjType["PUB"] = 80] = "PUB";
    ObjType[ObjType["DATA_PROVIDER"] = 81] = "DATA_PROVIDER";
    ObjType[ObjType["CREATIVE_FLIGHT"] = 82] = "CREATIVE_FLIGHT";
    ObjType[ObjType["SOURCE"] = 83] = "SOURCE";
    ObjType[ObjType["STATION"] = 84] = "STATION";
    ObjType[ObjType["USR"] = 85] = "USR";
    ObjType[ObjType["ADV"] = 86] = "ADV";
    ObjType[ObjType["WLIST"] = 87] = "WLIST";
    ObjType[ObjType["CONTENT_PROVIDER"] = 88] = "CONTENT_PROVIDER";
    ObjType[ObjType["PIXEL"] = 89] = "PIXEL";
    ObjType[ObjType["ISP"] = 90] = "ISP";
    ObjType[ObjType["CREATIVE_VID"] = 97] = "CREATIVE_VID";
    ObjType[ObjType["SSP"] = 98] = "SSP";
    ObjType[ObjType["GEO_LOC"] = 99] = "GEO_LOC";
    ObjType[ObjType["ANCESTOR"] = 95] = "ANCESTOR";
    ObjType[ObjType["BEACON"] = 115] = "BEACON";
})(ObjType || (ObjType = {}));
export var PacingStatus;
(function (PacingStatus) {
    PacingStatus["WAY_OVER"] = "way-over";
    PacingStatus["OVER"] = "over";
    PacingStatus["WAITING"] = "waiting";
    PacingStatus["ON"] = "on";
    PacingStatus["UNDER"] = "under";
    PacingStatus["WAY_UNDER"] = "way-under";
    PacingStatus["FUTURE"] = "future";
    PacingStatus["ACTIVE_WAITING"] = "active-waiting";
})(PacingStatus || (PacingStatus = {}));
export var PacingType;
(function (PacingType) {
    PacingType["ACTIVE"] = "active";
    PacingType["COMPLETED"] = "completed";
    PacingType["WAITING"] = "waiting";
    PacingType["ACTIVE_WAITING"] = "active-waiting";
})(PacingType || (PacingType = {}));
export var PrimaryUserTypes;
(function (PrimaryUserTypes) {
    PrimaryUserTypes["INTERNAL_STANDARD"] = "Internal (Non-Admin)";
    PrimaryUserTypes["INTERNAL_ADMIN"] = "Internal (Admin)";
    PrimaryUserTypes["EXTERNAL"] = "Client";
})(PrimaryUserTypes || (PrimaryUserTypes = {}));
export var ServiceDeviceNames;
(function (ServiceDeviceNames) {
    ServiceDeviceNames["CTV"] = "TV";
    ServiceDeviceNames["DESKTOP"] = "DESKTOP";
    ServiceDeviceNames["MOBILE"] = "MOBILE";
    ServiceDeviceNames["TABLET"] = "TABLET";
})(ServiceDeviceNames || (ServiceDeviceNames = {}));
export var ServiceSortDirection;
(function (ServiceSortDirection) {
    ServiceSortDirection["ASC"] = "asc";
    ServiceSortDirection["DESC"] = "desc";
})(ServiceSortDirection || (ServiceSortDirection = {}));
/**
 * Represents the state of a MadHive resource.
 */
// prettier-ignore
export var ServiceStatus;
(function (ServiceStatus) {
    /** should typically not be used */
    ServiceStatus[ServiceStatus["INVALID"] = 0] = "INVALID";
    ServiceStatus[ServiceStatus["DRAFT"] = 100] = "DRAFT";
    ServiceStatus[ServiceStatus["READY"] = 200] = "READY";
    ServiceStatus[ServiceStatus["PAUSED"] = 300] = "PAUSED";
    ServiceStatus[ServiceStatus["CANCELLED"] = 400] = "CANCELLED";
    ServiceStatus[ServiceStatus["ARCHIVED"] = 500] = "ARCHIVED";
})(ServiceStatus || (ServiceStatus = {}));
/**
 *  Client-side rep of a MadHive resource state.
 */
export var State;
(function (State) {
    State["DRAFT"] = "Draft";
    State["READY"] = "Ready";
    State["PAUSED"] = "Paused";
    State["CANCELLED"] = "Cancelled";
    State["ARCHIVED"] = "Archived";
    /** should typically not be used */
    State["INVALID"] = "Invalid";
})(State || (State = {}));
export var UserType;
(function (UserType) {
    UserType["AGENCY"] = "agency";
    UserType["ADVERTISER"] = "advertiser";
    UserType["ORGANIZATION"] = "organization";
    UserType["DEV"] = "dev";
    UserType["ADMIN"] = "admin";
    /** is root user (dev@madhive.com) */
    UserType["ROOT"] = "root";
})(UserType || (UserType = {}));
/** These strings need to match up with the strings in a user's `views` array in
 * Firestore and with the keys in an organization's `views` map. */
export var ViewId;
(function (ViewId) {
    ViewId["CREATIVE_LIBRARY"] = "creative-library";
    ViewId["MANAGE_CAMPAIGNS"] = "manage-campaigns";
    ViewId["DASHBOARD"] = "dashboard";
    ViewId["TRACKING_PIXELS"] = "tracking-pixels";
    ViewId["ADMIN_USERS"] = "admin-users";
    ViewId["MANAGE_PUBLISHER_GROUPS"] = "manage-publisher-groups";
    ViewId["PACING"] = "pacing";
    ViewId["MANAGE_ADVERTISERS"] = "manage-advertisers";
    ViewId["MANAGE_AGENCIES"] = "manage-agencies";
    ViewId["MANAGE_STATIONS"] = "manage-stations";
    ViewId["MANAGE_PRODUCTS"] = "manage-products";
    ViewId["MANAGE_DEALS"] = "manage-deals";
    ViewId["DELIVERY_REPORT"] = "delivery-report";
    ViewId["ADBOOK_CAMPAIGNS"] = "adbook-campaigns";
    ViewId["LINEAR_MEASUREMENT"] = "linear-measurement";
    ViewId["MANAGE_AUDIENCES"] = "manage-audiences";
    ViewId["CUSTOM_REPORTING"] = "custom-reporting";
    ViewId["CRYPTO_DASHBOARD"] = "crypto-dashboard";
    ViewId["OOH_PLANNING"] = "planning";
    ViewId["CROSS_PLATFORM_PLANNER"] = "cross-platform-planner";
    ViewId["DATASTUDIO_REPORTS"] = "datastudio-reports";
    ViewId["MANAGE_PUBLISHERS"] = "manage-publishers";
    ViewId["WIDE_ORBIT_ERRORS"] = "wide-orbit-errors";
    ViewId["PLANNER"] = "planner";
    ViewId["ADVANCED_EXPORTS"] = "advanced-exports";
    ViewId["FOOT_TRAFFIC_ATTRIBUTION"] = "foot-traffic-attribution";
    ViewId["FIRST_PARTY_DATA_ONBOARDING"] = "pii";
    ViewId["ADMIN"] = "admin";
    ViewId["FIVE_MIN_SEPARATION"] = "five-min-separation";
    ViewId["ATTRIBUTION_DASHBOARD"] = "attribution-dashboard";
    ViewId["CONVERSION_DASHBOARD"] = "conversion-dashboard";
    ViewId["SPEND_DASHBOARD"] = "spend-dashboard";
    ViewId["AUDIENCE_DATA_PROVISIONING"] = "audience-data-provisioning";
    ViewId["BI_CONVERSION_DASHBOARD"] = "bi-conversion-dashboard";
    ViewId["REPORT_BUILDER"] = "report-builder";
    ViewId["REPORTS"] = "bi-reports";
    ViewId["PACING_BETA"] = "pacing-beta";
})(ViewId || (ViewId = {}));
