import { useMadSDKList, useMadSDKSingular } from "../../../hooks/madSDK";
import { DealGroupType, FilterTypes } from "@madhive/mad-sdk";
export const DEFAULT_DEAL_GROUPS_TYPES_FILTER = {
    field: "typeFilter",
    type: FilterTypes.EQ,
    value: DealGroupType.GROUP
};
export const DEFAULT_DEAL_TYPES_FILTER = {
    field: "typeFilter",
    type: FilterTypes.EQ,
    value: DealGroupType.INVENTORY
};
export const ALLOWED_DEAL_GROUPS_USER_FILTERS = ["archiveStatus", "search"];
/**
 * Hook that returns a Deal
 * @param id id of the deal
 * @param options
 * @returns {SingularData<Deal>}
 */
export const useDeal = (id, options) => useMadSDKSingular("deals", id, options);
/**
 * Hook that returns a list of all deals
 * @param options
 * @returns {ListData<Deal>}
 */
export const useDeals = (options) => useMadSDKList("deals", options);
/**
 * Hook that returns a list of all deal groups
 * @param options
 * @returns {ListData<Deal>}
 */
export const useDealGroups = (options) => {
    const groupOptions = { ...(options || {}) };
    const filters = groupOptions?.filters || {};
    const groupFilters = {
        ...filters,
        where: [
            ...(filters.where || []).filter((where) => ALLOWED_DEAL_GROUPS_USER_FILTERS.includes(where.field)),
            DEFAULT_DEAL_GROUPS_TYPES_FILTER
        ]
    };
    groupOptions.filters = groupFilters;
    return useMadSDKList("deals", groupOptions);
};
