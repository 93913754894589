import { forwardRef, MutableRefObject, ReactNode } from "react";
import { styles } from "../styles";

/**
 * `List.SectionTitle` is used to render a section title in a list.
 * @example
 * ```tsx
 * <List>
 *  <List.SectionTitle id="things">Things:</List.SectionTitle>
 *  <List.Item section="things">Thing #1</List.Item>
 *  <List.Item section="things">Thing things</List.Item>
 *
 *  <List.Divider />
 *  <List.SectionTitle id="stuff">Stuff:</List.SectionTitle>
 *  <List.Item section="stuff">Stuff #1</List.Item>
 *  ...
 * </List>
 */
const ListSectionTitle = forwardRef(function ListSectionTitle(
  {
    id,
    children
  }: {
    children: ReactNode;
    id: string;
  },
  ref: MutableRefObject<HTMLLegendElement | null>
) {
  if (!children) {
    return null;
  }
  return (
    <legend
      className="kit-ListSectionTitle"
      id={id}
      ref={ref}
      role="presentation"
      css={[styles.sectionTitle]}
    >
      {children}
    </legend>
  );
});

ListSectionTitle.displayName = "List.SectionTitle";
export default ListSectionTitle;
