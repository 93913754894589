import { PacingStatus, PacingType } from "../../../types";
import { getPacingStatus, getPacingType } from "../../../utils";
export const isPacingLineItem = (pacingEntry) => {
    return "campaignId" in pacingEntry;
};
export const isPacingCampaign = (pacingEntry) => {
    return "lineItems" in pacingEntry;
};
const derivePacing = (pacingEntry) => {
    const delivered = pacingEntry.pacingType === "Impressions"
        ? pacingEntry.totalDeliveredImpressions
        : pacingEntry.totalDeliveredSpend;
    const pacingOverGoal = delivered / pacingEntry.pacingGoal;
    const currentTime = new Date().getTime();
    const startTime = new Date(pacingEntry.startDate).getTime();
    const endTime = new Date(pacingEntry.endDate).getTime();
    const pacingType = getPacingType(delivered, currentTime, startTime, endTime);
    const pacingStatus = getPacingStatus(pacingOverGoal * 100, pacingType);
    if (isPacingCampaign(pacingEntry)) {
        return {
            ...pacingEntry,
            timeStatus: pacingType,
            pacingStatus,
            lineItems: pacingEntry.lineItems
                .map(derivePacing)
                .sort((a, b) => a.name.localeCompare(b.name))
        };
    }
    return {
        ...pacingEntry,
        timeStatus: pacingType,
        pacingStatus
    };
};
/**
 * Transforms the raw data from Looker into a normalized list of campaigns with its corresponding line items
 *
 * @param data The raw data from Looker for each line item
 * @returns A normalized list of campaigns with its corresponding line items and pacing data
 */
export const parsePacingResponse = (data) => {
    const campaigns = {};
    data.forEach((entry) => {
        const campaignId = entry["vw_dim_lineitem.campaign__id"];
        if (!campaigns[campaignId]) {
            campaigns[campaignId] = {
                id: campaignId,
                pacingStatus: PacingStatus.WAITING,
                timeStatus: PacingType.WAITING,
                name: entry["vw_dim_lineitem.campaign__name"],
                pacingGoal: entry["vw_dim_lineitem.pacing_goal"],
                pacingType: entry["vw_dim_lineitem.lineitem_type"],
                stationName: entry["vw_dim_lineitem.station__name"] || "--",
                advertiserName: entry["vw_dim_lineitem.advertiser__name"],
                advertiserId: entry["vw_dim_lineitem.advertiser__id"],
                products: new Map([
                    [entry["vw_dim_lineitem.product__id"], entry["vw_dim_lineitem.product__name"]]
                ]),
                startDate: entry["vw_dim_lineitem.campaign_start_date"],
                endDate: entry["vw_dim_lineitem.campaign_end_date"],
                totalDeliveredImpressions: entry["vw_fct_impressions.total_impressions"],
                totalDeliveredSpend: entry["vw_fct_impressions.total_spend"],
                remainingImpressions: entry["vw_fct_impressions.remaining_impressions"],
                remainingBudget: entry["vw_fct_impressions.remaining_budget"],
                impressionsGoal: entry["vw_fct_impressions.delivered_goal"],
                spendGoal: entry["vw_dim_lineitem.spend_goal"],
                advertiserCategoryName: entry["vw_dim_lineitem.advertiser__iab_category__raw__name"],
                advertiserCategoryId: entry["vw_dim_lineitem.advertiser__iab_category__raw__code"],
                creativeCategoryName: entry["vw_dim_creative_categories.raw__name"],
                creativeCategoryId: entry["vw_dim_creative_categories.raw__code"],
                lineItems: []
            };
        }
        else {
            campaigns[campaignId].pacingGoal += entry["vw_dim_lineitem.pacing_goal"];
            campaigns[campaignId].totalDeliveredImpressions +=
                entry["vw_fct_impressions.total_impressions"];
            campaigns[campaignId].totalDeliveredSpend += entry["vw_fct_impressions.total_spend"];
            campaigns[campaignId].remainingImpressions +=
                entry["vw_fct_impressions.remaining_impressions"];
            campaigns[campaignId].remainingBudget += entry["vw_fct_impressions.remaining_budget"];
            campaigns[campaignId].impressionsGoal += entry["vw_fct_impressions.delivered_goal"];
            campaigns[campaignId].spendGoal += entry["vw_dim_lineitem.spend_goal"];
            campaigns[campaignId].products.set(entry["vw_dim_lineitem.product__id"], entry["vw_dim_lineitem.product__name"]);
        }
        campaigns[campaignId].lineItems.push({
            id: entry["vw_dim_lineitem.lineitem__id"],
            campaignId,
            products: new Map([
                [entry["vw_dim_lineitem.product__id"], entry["vw_dim_lineitem.product__name"]]
            ]),
            name: entry["vw_dim_lineitem.lineitem__name"],
            pacingType: entry["vw_dim_lineitem.lineitem_type"],
            pacingStatus: PacingStatus.WAITING,
            timeStatus: PacingType.WAITING,
            pacingGoal: entry["vw_dim_lineitem.pacing_goal"],
            stationName: entry["vw_dim_lineitem.station__name"] || "--",
            advertiserName: entry["vw_dim_lineitem.advertiser__name"],
            advertiserId: entry["vw_dim_lineitem.advertiser__id"],
            advertiserCategoryName: entry["vw_dim_lineitem.advertiser__iab_category__raw__name"],
            advertiserCategoryId: entry["vw_dim_lineitem.advertiser__iab_category__raw__code"],
            creativeCategoryName: entry["vw_dim_creative_categories.raw__name"],
            creativeCategoryId: entry["vw_dim_creative_categories.raw__code"],
            startDate: entry["vw_dim_lineitem.lineitem_start_date"],
            endDate: entry["vw_dim_lineitem.lineitem_end_date"],
            totalDeliveredImpressions: entry["vw_fct_impressions.total_impressions"],
            totalDeliveredSpend: entry["vw_fct_impressions.total_spend"],
            remainingImpressions: entry["vw_fct_impressions.remaining_impressions"],
            remainingBudget: entry["vw_fct_impressions.remaining_budget"],
            impressionsGoal: entry["vw_fct_impressions.delivered_goal"],
            spendGoal: entry["vw_dim_lineitem.spend_goal"]
        });
    });
    return Object.values(campaigns)
        .map(derivePacing)
        .sort((a, b) => a.name.localeCompare(b.name));
};
