import { Intent } from "@blueprintjs/core";
import { PeerSave } from "@madhive/mad-sdk/modules/peerSaves/types";
import { useToast } from "frontier/lib/hooks/stateful/useToast";
import { Button } from "frontier/lib/kit";
import { RemoteConfigProperties, useFeatureFlag } from "hooks/useFeatureFlag";
import { dateDisplayTime, displayDate } from "lib/utils/date";
import { useEffect } from "react";

export const useDataChangedToast = (
  saved: PeerSave | undefined,
  dataType: string = "data"
) => {
  const addToast = useToast();
  const isSaveAlertsToastsFeatureEnabled = useFeatureFlag(
    RemoteConfigProperties.PEER_CHANGES_SAVE_ALERTS_TOASTS
  );

  useEffect(() => {
    if (!isSaveAlertsToastsFeatureEnabled) {
      return;
    }

    if (saved) {
      const { user, timestamp } = saved;
      const date = new Date(timestamp);

      addToast({
        title: "New Version Published",
        intent: Intent.WARNING,
        message: (
          <>
            {user?.email} published a new version of this{" "}
            {saved.dataType || dataType} on {displayDate(date)} at{" "}
            {dateDisplayTime(date)}.{" "}
            <Button variant="ghost" onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          </>
        ),
        timeout: 0
      });
    }
  }, [saved]);
};
