import { useMemo } from "react";
import ListItem from "./Elements/Item";
import { ListProps } from "./types";
import {
  isListWithData,
  isListWithSearch,
  isListWithSelectableItems,
  isListWithVirtualScroll,
  listWithData,
  listWithSearch,
  listWithSelectableItems,
  withListWrapper
} from "./HOC";
import ListSectionTitle from "./Elements/SectionTitle";
import ListDivider from "./Elements/Divider";
import ListHeader from "./Elements/Header";
import BaseList from "./Base";

/**
 * `List` is a component that renders a list of items.
 * and handles:
 * - Selecting items
 * - Virtual scrolling
 * - Dynamic data rendering
 * - Sections
 * - Searching
 * - Keyboard navigation
 * - and more...
 */
function List<T>(props: ListProps<T>) {
  const List = useMemo(() => {
    let List = withListWrapper(BaseList);

    if (isListWithData(props)) {
      /** Add data handling */
      List = listWithData(List);
    }

    if (isListWithSearch(props)) {
      /** Add searching functionality */
      List = listWithSearch(List);
    }

    if (isListWithSelectableItems(props)) {
      /** Add selecting functionality */
      List = listWithSelectableItems(List);
    }

    return List;
  }, [
    isListWithData(props),
    isListWithVirtualScroll(props),
    isListWithSelectableItems(props),
    isListWithSearch(props)
  ]);

  return <List {...props} />;
}

List.Item = ListItem;
List.SectionTitle = ListSectionTitle;
List.Divider = ListDivider;
List.Header = ListHeader;

export default List;
export * from "./types";
