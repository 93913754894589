import { TableRowType, TableColumnProps, TableRowActionProps } from ".";
import { IdType } from "../types";

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

export function TableColumn<
  Row extends TableRowType,
  ColumnKey extends IdType = IdType
>(props: TableColumnProps<Row, ColumnKey>) {
  return <></>;
}
TableColumn.displayName = "Table.Column";

export function TableRowAction<Row extends TableRowType>(
  props: TableRowActionProps<Row>
) {
  return <></>;
}
TableRowAction.displayName = "Table.RowAction";
