import React from "react";
import { IdType } from "./lookups";
import { DoNotCare } from "./utils";

export const isIdType = (value: DoNotCare): value is IdType =>
  typeof value === "string" || typeof value === "number";

export function isReactNode(value: DoNotCare): value is React.ReactNode {
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    value === null ||
    value === undefined ||
    React.isValidElement(value) ||
    (Array.isArray(value) && value.every(isReactNode))
  ) {
    return true;
  }
  return false;
}
