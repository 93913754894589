import { useState, useEffect } from "react";
import Flyout from "../../Flyout";
import { DoNotCare } from "../../types";
import { DropdownProps, SelectedType, ItemType } from "../types";

/**
 * Simple type guard to determine if a Dropdown component props
 * has children (the trigger element for Flyout).
 */
export const isDropdownWithFlyout = (props: DropdownProps) => {
  return !!props.children;
};

/**
 * Higher Order Component renders Dropdown in a Flyout.
 * @param WrappedComponent The kit Dropdown component to wrap.
 * @returns A new component that renders the Dropdown in a Flyout, with the initial + additional props
 */
export function dropdownWithFlyout<
  Selected extends SelectedType,
  Item extends ItemType
>(WrappedComponent: React.ComponentType<DropdownProps<DoNotCare, DoNotCare>>) {
  /**
   * Dropdown component that includes rendering in a Flyout.
   * @param props The props for the dropdown component. {@link DropdownProps}
   */
  function Component(props: DropdownProps<Selected, Item>) {
    const { flyoutProps, fill, children } = props;

    const [isOpen, setIsOpen] = useState(!!flyoutProps?.isOpen);

    useEffect(() => setIsOpen(!!flyoutProps?.isOpen), [flyoutProps?.isOpen]);

    const handleOnClose = () => {
      setIsOpen(false);
      flyoutProps?.onClose?.();
    };

    const handleOnOpen = () => {
      setIsOpen(true);
      flyoutProps?.onOpen?.();
    };

    return (
      <Flyout
        position="bottom"
        offset="0, 4px"
        fill={fill}
        {...flyoutProps}
        lazy={flyoutProps?.lazy !== false}
        autoFocus={flyoutProps?.autoFocus === true}
        enforceFocus={flyoutProps?.enforceFocus === true}
        target={children || <span />}
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        boundary="scrollParent"
      >
        <WrappedComponent {...props} />
      </Flyout>
    );
  }

  return Component;
}
