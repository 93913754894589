import { BrandingId, ClientDeviceNames, DayOfTheWeek, DeviceNames, GeographicEntityTypes, KnownOrganizationIds, KnownOrganizationNames, KnownProjectIds, ObjType, ServiceDeviceNames, ViewId } from "./enums";
export const CLIENT_DEVICE_TO_DEVICE_NAME = {
    [ClientDeviceNames.DESKTOP]: DeviceNames.DESKTOP,
    [ClientDeviceNames.MOBILE]: DeviceNames.MOBILE,
    [ClientDeviceNames.TABLET]: DeviceNames.TABLET,
    [ClientDeviceNames.CTV]: DeviceNames.CTV
};
export const CLIENT_TO_SERVICE_DEVICE_CAP = {
    [ClientDeviceNames.DESKTOP]: ServiceDeviceNames.DESKTOP,
    [ClientDeviceNames.MOBILE]: ServiceDeviceNames.MOBILE,
    [ClientDeviceNames.TABLET]: ServiceDeviceNames.TABLET,
    [ClientDeviceNames.CTV]: ServiceDeviceNames.CTV
};
export const DEFAULT_SERVICE_USER_SETTINGS = {
    base_app_name: "MadHive",
    homepage: ViewId.DASHBOARD,
    footer: true,
    custom_segment_selection: "fox",
    asset_specification_id: "basic",
    app_settings: {
        campaigns_frequency_settings: {
            daily_freq_cap: 6,
            hourly_freq_cap: 2,
            has_freq_cap: true
        }
    },
    dashboard: {
        available_dates: {
            min: 10000000
        },
        dashboard_filter_settings: {
            adbook: { is_set: false, value: false },
            agency: { is_set: false, value: false },
            advertiser: { is_set: true, value: true },
            campaign: { is_set: true, value: true },
            product: { is_set: true, value: true },
            station: { is_set: false, value: false },
            team: { is_set: false, value: false }
        }
    },
    columns: {
        manage_campaigns_columns: []
    },
    ext_meta: {
        oms_id_field_name: "OMS ID",
        oms_label: "OMS",
        is_oms_disabled: true,
        external_id_field_name: "External ID",
        billing_field_name: "Billing ID"
    },
    theme: {
        hue: 224,
        saturation: 100,
        lightness: 50,
        toolbar_color: "#ffffff",
        logo_size: 75
    },
    static_assets: {
        logo: "",
        favicon: ""
    }
};
export const DEFAULT_USER_SETTINGS = {
    baseAppName: "MadHive",
    homepage: ViewId.DASHBOARD,
    footer: true,
    customSegmentSelection: "fox",
    assetSpecificationId: "basic",
    appSettings: {
        campaignsFrequencySettings: {
            dailyFreqCap: 6,
            hourlyFreqCap: 2,
            hasFreqCap: true
        }
    },
    dashboard: {
        availableDates: {
            min: 10000000
        },
        dashboardFilterSettings: {
            adbook: { isSet: false, value: false },
            agency: { isSet: false, value: false },
            advertiser: { isSet: true, value: true },
            campaign: { isSet: true, value: true },
            product: { isSet: true, value: true },
            station: { isSet: false, value: false },
            team: { isSet: false, value: false }
        }
    },
    columns: {
        manageCampaignsColumns: []
    },
    extMeta: {
        omsIdFieldName: "OMS ID",
        omsLabel: "OMS",
        isOmsDisabled: true,
        externalIdFieldName: "External ID",
        billingFieldName: "Billing ID"
    },
    theme: {
        hue: 224,
        saturation: 100,
        lightness: 50,
        toolbarColor: "#ffffff",
        logoSize: 75
    },
    staticAssets: {
        logo: "",
        favicon: ""
    }
};
export const GEO_ENTITY_TO_HERMES_DELIVERY_ENDPOINT = {
    [GeographicEntityTypes.STATE]: "state_impressions",
    [GeographicEntityTypes.DISTRICT]: "congressional_impressions",
    [GeographicEntityTypes.DMA]: "dma_impressions",
    [GeographicEntityTypes.ZIP_CODE]: "postal_impressions"
};
/**
 * Map of firebase project to corresponding google endpoints project location.
 * Google endpoints including NewNewYork and Hermes are located in `mad-master`
 * for production domains. Otherwise, google endpoint project IDs match the
 * frontend firebase project IDs 1-to-1 (i.e. `mad-staging` <=> `mad-staging`)
 */
export const FIREBASE_PROJECT_TO_GOOGLE_ENDPOINTS = {
    [KnownProjectIds.MAD_MASTER]: KnownProjectIds.MAD_MASTER,
    [KnownProjectIds.MAD_STAGING]: KnownProjectIds.MAD_STAGING,
    [KnownProjectIds.MAD_QA]: KnownProjectIds.MAD_QA,
    [KnownProjectIds.MAD_HACK]: KnownProjectIds.MAD_HACK,
    [KnownProjectIds.MAD_DEMO]: KnownProjectIds.MAD_DEMO,
    [KnownProjectIds.MADHIVE_TESTING]: KnownProjectIds.MADHIVE_TESTING,
    [KnownProjectIds.MAD_TALON]: KnownProjectIds.MAD_TALON
};
export const makeEmptyDeviceCaps = () => ({
    [ClientDeviceNames.DESKTOP]: 0,
    [ClientDeviceNames.MOBILE]: 0,
    [ClientDeviceNames.TABLET]: 0,
    [ClientDeviceNames.CTV]: 0
});
export const makeEmptyLineItemDayparts = () => ({
    [DayOfTheWeek.SUNDAY]: [],
    [DayOfTheWeek.MONDAY]: [],
    [DayOfTheWeek.TUESDAY]: [],
    [DayOfTheWeek.WEDNESDAY]: [],
    [DayOfTheWeek.THURSDAY]: [],
    [DayOfTheWeek.FRIDAY]: [],
    [DayOfTheWeek.SATURDAY]: []
});
export const ORGANIZATION_ID_TO_BRANDING_ID = {
    [KnownOrganizationIds.MADHIVE_DEFAULT]: BrandingId.MADHIVE,
    [KnownOrganizationIds.MADHIVE_DEMO]: BrandingId.MADHIVE,
    [KnownOrganizationIds.MADHIVE_MEDIA_GROUP]: BrandingId.MADHIVE,
    [KnownOrganizationIds.PREMION]: BrandingId.PREMION,
    [KnownOrganizationIds.SCRIPPS]: BrandingId.SCRIPPS,
    [KnownOrganizationIds.BOBMILLS]: BrandingId.MADHIVE,
    [KnownOrganizationIds.RUNNINGMATE]: BrandingId.RUNNINGMATE,
    [KnownOrganizationIds.MEDIAMATH]: BrandingId.MADHIVE,
    [KnownOrganizationIds.DIGILANT_ARCHIVED]: BrandingId.DIGILANT_ARCHIVED,
    [KnownOrganizationIds.FUTURESMEDIA]: BrandingId.FUTURESMEDIA,
    [KnownOrganizationIds.BEACHFRONT]: BrandingId.MADHIVE,
    [KnownOrganizationIds.BIG_SANDY_PROMOTIONS]: BrandingId.OCTILLION_MEDIA,
    [KnownOrganizationIds.ASSOCIATED_VOLUME_BUYERS]: BrandingId.AVB,
    [KnownOrganizationIds.BTC_MEDIA]: BrandingId.MADHIVE,
    [KnownOrganizationIds.UAT_MEDIA]: BrandingId.MADHIVE,
    [KnownOrganizationIds.FOX]: BrandingId.FOX,
    [KnownOrganizationIds.NEWSY]: BrandingId.NEWSY,
    [KnownOrganizationIds.RESIDENT_HOME]: BrandingId.RESIDENT_HOME,
    [KnownOrganizationIds.VOICE_MEDIA]: BrandingId.VOICE_MEDIA,
    [KnownOrganizationIds.LEGAL_ACTION_MEDIA]: BrandingId.LEGAL_ACTION_MEDIA,
    [KnownOrganizationIds.FOSTERS_LAB]: BrandingId.MADHIVE,
    [KnownOrganizationIds.MODUS_DIRECT]: BrandingId.MODUS_DIRECT,
    [KnownOrganizationIds.TEAM_LEWIS]: BrandingId.TEAM_LEWIS,
    [KnownOrganizationIds.KING]: BrandingId.KING,
    [KnownOrganizationIds.DX_AGENCY]: BrandingId.DX_AGENCY,
    [KnownOrganizationIds.LINCOLN_DIGITAL_GROUP]: BrandingId.LINCOLN_DIGITAL_GROUP,
    [KnownOrganizationIds.NMPI]: BrandingId.NMPI,
    [KnownOrganizationIds.PATHFINDER_INTERACTIVE]: BrandingId.PATHFINDER_INTERACTIVE,
    [KnownOrganizationIds.CTV_MEDIA]: BrandingId.CTV_MEDIA,
    [KnownOrganizationIds.NEWS_PRESS_GAZETTE]: BrandingId.NEWS_PRESS_GAZETTE,
    [KnownOrganizationIds.TALON]: BrandingId.TALON,
    [KnownOrganizationIds.IHEART]: BrandingId.IHEART,
    [KnownOrganizationIds.OMNICOM]: BrandingId.MADHIVE,
    [KnownOrganizationIds.CBC]: BrandingId.CBC,
    [KnownOrganizationIds.GROUNDTRUTH]: BrandingId.GROUNDTRUTH,
    [KnownOrganizationIds.COX]: BrandingId.COX,
    [KnownOrganizationIds.LOCALFACTOR]: BrandingId.LOCALFACTOR,
    [KnownOrganizationIds.ALLENMEDIA]: BrandingId.ALLENMEDIA,
    [KnownOrganizationIds.GRIFFIN]: BrandingId.GRIFFIN,
    [KnownOrganizationIds.HEARST]: BrandingId.HEARST,
    [KnownOrganizationIds.CAPITOL_BROADCASTING]: BrandingId.CAPITOL_BROADCASTING,
    [KnownOrganizationIds.MCAFEE]: BrandingId.MCAFEE,
    [KnownOrganizationIds.JUICE_MEDIA]: BrandingId.JUICE_MEDIA,
    [KnownOrganizationIds.GOOD_KARMA]: BrandingId.GOOD_KARMA,
    [KnownOrganizationIds.MEDIAMATH]: BrandingId.MEDIAMATH,
    [KnownOrganizationIds.UNIVISION]: BrandingId.UNIVISION,
    [KnownOrganizationIds.GYDIGITAL]: BrandingId.GYDIGITAL,
    [KnownOrganizationIds.MADHIVE_INTEGRATION_TESTING_ORG]: BrandingId.MADHIVE,
    [KnownOrganizationIds.KATZ_DIGITAL]: BrandingId.KATZ_DIGITAL,
    [KnownOrganizationIds.SPARKLIGHT]: BrandingId.SPARKLIGHT,
    [KnownOrganizationIds.HP]: BrandingId.HP,
    [KnownOrganizationIds.NOM]: BrandingId.NOM,
    [KnownOrganizationIds.BEN_HER]: BrandingId.BEN_HER,
    [KnownOrganizationIds.THE_REAL_REAL]: BrandingId.THE_REAL_REAL,
    [KnownOrganizationIds.BRANT_MEDIA]: BrandingId.BRANT_MEDIA,
    [KnownOrganizationIds.GAMUT]: BrandingId.GAMUT,
    [KnownOrganizationIds.EVERGREEN]: BrandingId.EVERGREEN,
    [KnownOrganizationIds.BAHAKEL]: BrandingId.BAHAKEL,
    [KnownOrganizationIds.ANALYTICS_OWL]: BrandingId.ANALYTICS_OWL,
    [KnownOrganizationIds.DLM_AGENCY]: BrandingId.DLM_AGENCY,
    [KnownOrganizationIds.MEDICX]: BrandingId.MEDICX,
    [KnownOrganizationIds.TOWNSQUARE]: BrandingId.TOWNSQUARE,
    [KnownOrganizationIds.DIGITAL_ANALYTICS_PRO]: BrandingId.DIGITAL_ANALYTICS_PRO,
    [KnownOrganizationIds.BLOCK_COMMUNICATIONS]: BrandingId.BLOCK_COMMUNICATIONS,
    [KnownOrganizationIds.TURNER_SPORTS]: BrandingId.TURNER_SPORTS,
    [KnownOrganizationIds.HOMESLICE_GROUP]: BrandingId.HOMESLICE_GROUP,
    [KnownOrganizationIds.MONDAY]: BrandingId.MONDAY,
    [KnownOrganizationIds.WIN_DIGITAL]: BrandingId.WIN_DIGITAL,
    [KnownOrganizationIds.CHARLTON_MARKETING]: BrandingId.CHARLTON_MARKETING,
    [KnownOrganizationIds.TALMONT]: BrandingId.TALMONT,
    [KnownOrganizationIds.FIVE_STAR_MARKETING_ADVISORS]: BrandingId.FIVE_STAR_MARKETING_ADVISORS,
    [KnownOrganizationIds.EVIDNT]: BrandingId.EVIDNT,
    [KnownOrganizationIds.DISH_SLING]: BrandingId.DISH_SLING,
    [KnownOrganizationIds.T_MOBILE]: BrandingId.T_MOBILE,
    [KnownOrganizationIds.AI_DIGITAL]: BrandingId.AI_DIGITAL,
    [KnownOrganizationIds.TRIP_ADVISOR]: BrandingId.TRIP_ADVISOR,
    [KnownOrganizationIds.LIFTOFF]: BrandingId.LIFTOFF,
    [KnownOrganizationIds.MiQ]: BrandingId.MiQ,
    [KnownOrganizationIds.OMG]: BrandingId.OMG,
    [KnownOrganizationIds.OTTERA]: BrandingId.OTTERA,
    [KnownOrganizationIds.VOGLIO]: BrandingId.VOGLIO,
    [KnownOrganizationIds.ARKADAS_GROUP]: BrandingId.ARKADAS_GROUP,
    [KnownOrganizationIds.SAKS_OFF_FIFTH]: BrandingId.SAKS_OFF_FIFTH,
    [KnownOrganizationIds.BUCKEYE_BROADBAND]: BrandingId.BUCKEYE_BROADBAND,
    [KnownOrganizationIds.LIFEBRANDS_D2C]: BrandingId.LIFEBRANDS_D2C,
    [KnownOrganizationIds.KILL_YOUR_COMPETITION]: BrandingId.KILL_YOUR_COMPETITION,
    [KnownOrganizationIds.SONOBI]: BrandingId.SONOBI,
    [KnownOrganizationIds.BATEMAN_COLLECTIVE]: BrandingId.BATEMAN_COLLECTIVE,
    [KnownOrganizationIds.FEDERATED_DIGITAL_SOLUTIONS]: BrandingId.FEDERATED_DIGITAL_SOLUTIONS,
    [KnownOrganizationIds.KR8_VENTURES]: BrandingId.KR8_VENTURES,
    [KnownOrganizationIds.ESSENTIALISM_ADVERTISING]: BrandingId.ESSENTIALISM_ADVERTISING,
    [KnownOrganizationIds.RED_VENTURES]: BrandingId.RED_VENTURES,
    [KnownOrganizationIds.HUDDLED_MASSES]: BrandingId.HUDDLED_MASSES,
    [KnownOrganizationIds.AUTOMATED_TESTING]: BrandingId.AUTOMATED_TESTING,
    [KnownOrganizationIds.GREEN_AND_WOOD_MEDIA]: BrandingId.GREEN_AND_WOOD_MEDIA,
    [KnownOrganizationIds.TASTEMADE]: BrandingId.TASTEMADE,
    [KnownOrganizationIds.DIGILANT]: BrandingId.DIGILANT,
    [KnownOrganizationIds.CHAMPIONSHIP_ADVERTISING]: BrandingId.CHAMPIONSHIP_ADVERTISING,
    [KnownOrganizationIds.CTV_BUYER]: BrandingId.CTV_BUYER,
    [KnownOrganizationIds.LEV_LANE]: BrandingId.LEV_LANE,
    [KnownOrganizationIds.THE_DIGITAL_RUCKUS]: BrandingId.THE_DIGITAL_RUCKUS,
    [KnownOrganizationIds.BRAVE]: BrandingId.BRAVE,
    [KnownOrganizationIds.VIDEOHEROES]: BrandingId.VIDEOHEROES,
    [KnownOrganizationIds.ENTHUSIAST_GAMING]: BrandingId.ENTHUSIAST_GAMING,
    [KnownOrganizationIds.CBS]: BrandingId.CBS,
    [KnownOrganizationIds.NIMBUS99]: BrandingId.NIMBUS99,
    [KnownOrganizationIds.CONTINUUM_MEDIA]: BrandingId.CONTINUUM_MEDIA,
    [KnownOrganizationIds.NEXSTAR]: BrandingId.NEXSTAR,
    [KnownOrganizationIds.BLAM_RETAIL]: BrandingId.BLAM_RETAIL,
    [KnownOrganizationIds.VICA_AI]: BrandingId.VICA_AI,
    [KnownOrganizationIds.NIGHTLIFE_TELEVISION]: BrandingId.NIGHTLIFE_TELEVISION,
    [KnownOrganizationIds.JRR_ADVERTISING]: BrandingId.JRR_ADVERTISING,
    [KnownOrganizationIds.ESTRELLA_MEDIA]: BrandingId.ESTRELLA_MEDIA,
    [KnownOrganizationIds.PERFORMCB]: BrandingId.PERFORMCB,
    [KnownOrganizationIds.NEW_ROAD_ADVERTISING]: BrandingId.NEW_ROAD_ADVERTISING,
    [KnownOrganizationIds.QUICKTURN_MEDIA]: BrandingId.QUICKTURN_MEDIA,
    [KnownOrganizationIds.CONNECTADTV]: BrandingId.CONNECTADTV,
    [KnownOrganizationIds.NRG_MEDIA]: BrandingId.NRG_MEDIA,
    [KnownOrganizationIds.LILLY_BROADCASTING]: BrandingId.LILLY_BROADCASTING
};
export const ORGANIZATION_ID_TO_SMITHERS_ORG_NAME = {
    [KnownOrganizationIds.MADHIVE_DEFAULT]: KnownOrganizationNames.MADHIVE_DEFAULT,
    [KnownOrganizationIds.MADHIVE_DEMO]: KnownOrganizationNames.MADHIVE_DEMO,
    [KnownOrganizationIds.MADHIVE_MEDIA_GROUP]: KnownOrganizationNames.MADHIVE_MEDIA_GROUP,
    [KnownOrganizationIds.PREMION]: KnownOrganizationNames.PREMION,
    [KnownOrganizationIds.SCRIPPS]: KnownOrganizationNames.SCRIPPS,
    [KnownOrganizationIds.BOBMILLS]: KnownOrganizationNames.BOBMILLS,
    [KnownOrganizationIds.MEDIAMATH]: KnownOrganizationNames.MEDIAMATH,
    [KnownOrganizationIds.DIGILANT_ARCHIVED]: KnownOrganizationNames.DIGILANT_ARCHIVED,
    [KnownOrganizationIds.FUTURESMEDIA]: KnownOrganizationNames.FUTURESMEDIA,
    [KnownOrganizationIds.RUNNINGMATE]: KnownOrganizationNames.RUNNINGMATE,
    [KnownOrganizationIds.BEACHFRONT]: KnownOrganizationNames.BEACHFRONT,
    [KnownOrganizationIds.BIG_SANDY_PROMOTIONS]: KnownOrganizationNames.BIG_SANDY_PROMOTIONS,
    [KnownOrganizationIds.ASSOCIATED_VOLUME_BUYERS]: KnownOrganizationNames.ASSOCIATED_VOLUME_BUYERS,
    [KnownOrganizationIds.BTC_MEDIA]: KnownOrganizationNames.BTC_MEDIA,
    [KnownOrganizationIds.UAT_MEDIA]: KnownOrganizationNames.UAT_MEDIA,
    [KnownOrganizationIds.FOX]: KnownOrganizationNames.FOX,
    [KnownOrganizationIds.NEWSY]: KnownOrganizationNames.NEWSY,
    [KnownOrganizationIds.RESIDENT_HOME]: KnownOrganizationNames.RESIDENT_HOME,
    [KnownOrganizationIds.VOICE_MEDIA]: KnownOrganizationNames.VOICE_MEDIA,
    [KnownOrganizationIds.LEGAL_ACTION_MEDIA]: KnownOrganizationNames.LEGAL_ACTION_MEDIA,
    [KnownOrganizationIds.FOSTERS_LAB]: KnownOrganizationNames.FOSTERS_LAB,
    [KnownOrganizationIds.MODUS_DIRECT]: KnownOrganizationNames.MODUS_DIRECT,
    [KnownOrganizationIds.TEAM_LEWIS]: KnownOrganizationNames.TEAM_LEWIS,
    [KnownOrganizationIds.KING]: KnownOrganizationNames.KING,
    [KnownOrganizationIds.DX_AGENCY]: KnownOrganizationNames.DX_AGENCY,
    [KnownOrganizationIds.LINCOLN_DIGITAL_GROUP]: KnownOrganizationNames.LINCOLN_DIGITAL_GROUP,
    [KnownOrganizationIds.NMPI]: KnownOrganizationNames.NMPI,
    [KnownOrganizationIds.PATHFINDER_INTERACTIVE]: KnownOrganizationNames.PATHFINDER_INTERACTIVE,
    [KnownOrganizationIds.CTV_MEDIA]: KnownOrganizationNames.CTV_MEDIA,
    [KnownOrganizationIds.NEWS_PRESS_GAZETTE]: KnownOrganizationNames.NEWS_PRESS_GAZETTE,
    [KnownOrganizationIds.TALON]: KnownOrganizationNames.TALON,
    [KnownOrganizationIds.IHEART]: KnownOrganizationNames.IHEART,
    [KnownOrganizationIds.OMNICOM]: KnownOrganizationNames.OMNICOM,
    [KnownOrganizationIds.CBC]: KnownOrganizationNames.CBC,
    [KnownOrganizationIds.GROUNDTRUTH]: KnownOrganizationNames.GROUNDTRUTH,
    [KnownOrganizationIds.COX]: KnownOrganizationNames.COX,
    [KnownOrganizationIds.LOCALFACTOR]: KnownOrganizationNames.LOCALFACTOR,
    [KnownOrganizationIds.ALLENMEDIA]: KnownOrganizationNames.ALLENMEDIA,
    [KnownOrganizationIds.GRIFFIN]: KnownOrganizationNames.GRIFFIN,
    [KnownOrganizationIds.HEARST]: KnownOrganizationNames.HEARST,
    [KnownOrganizationIds.CAPITOL_BROADCASTING]: KnownOrganizationNames.CAPITOL_BROADCASTING,
    [KnownOrganizationIds.MCAFEE]: KnownOrganizationNames.MCAFEE,
    [KnownOrganizationIds.JUICE_MEDIA]: KnownOrganizationNames.JUICE_MEDIA,
    [KnownOrganizationIds.GOOD_KARMA]: KnownOrganizationNames.GOOD_KARMA,
    [KnownOrganizationIds.UNIVISION]: KnownOrganizationNames.UNIVISION,
    [KnownOrganizationIds.GYDIGITAL]: KnownOrganizationNames.GYDIGITAL,
    [KnownOrganizationIds.MADHIVE_INTEGRATION_TESTING_ORG]: KnownOrganizationNames.MADHIVE_INTEGRATION_TESTING_ORG,
    [KnownOrganizationIds.KATZ_DIGITAL]: KnownOrganizationNames.KATZ_DIGITAL,
    [KnownOrganizationIds.SPARKLIGHT]: KnownOrganizationNames.SPARKLIGHT,
    [KnownOrganizationIds.HP]: KnownOrganizationNames.HP,
    [KnownOrganizationIds.NOM]: KnownOrganizationNames.NOM,
    [KnownOrganizationIds.BEN_HER]: KnownOrganizationNames.BEN_HER,
    [KnownOrganizationIds.THE_REAL_REAL]: KnownOrganizationNames.THE_REAL_REAL,
    [KnownOrganizationIds.BRANT_MEDIA]: KnownOrganizationNames.BRANT_MEDIA,
    [KnownOrganizationIds.GAMUT]: KnownOrganizationNames.GAMUT,
    [KnownOrganizationIds.EVERGREEN]: KnownOrganizationNames.EVERGREEN,
    [KnownOrganizationIds.BAHAKEL]: KnownOrganizationNames.BAHAKEL,
    [KnownOrganizationIds.ANALYTICS_OWL]: KnownOrganizationNames.ANALYTICS_OWL,
    [KnownOrganizationIds.DLM_AGENCY]: KnownOrganizationNames.DLM_AGENCY,
    [KnownOrganizationIds.MEDICX]: KnownOrganizationNames.MEDICX,
    [KnownOrganizationIds.TOWNSQUARE]: KnownOrganizationNames.TOWNSQUARE,
    [KnownOrganizationIds.DIGITAL_ANALYTICS_PRO]: KnownOrganizationNames.DIGITAL_ANALYTICS_PRO,
    [KnownOrganizationIds.BLOCK_COMMUNICATIONS]: KnownOrganizationNames.BLOCK_COMMUNICATIONS,
    [KnownOrganizationIds.TURNER_SPORTS]: KnownOrganizationNames.TURNER_SPORTS,
    [KnownOrganizationIds.HOMESLICE_GROUP]: KnownOrganizationNames.HOMESLICE_GROUP,
    [KnownOrganizationIds.MONDAY]: KnownOrganizationNames.MONDAY,
    [KnownOrganizationIds.WIN_DIGITAL]: KnownOrganizationNames.WIN_DIGITAL,
    [KnownOrganizationIds.CHARLTON_MARKETING]: KnownOrganizationNames.CHARLTON_MARKETING,
    [KnownOrganizationIds.TALMONT]: KnownOrganizationNames.TALMONT,
    [KnownOrganizationIds.FIVE_STAR_MARKETING_ADVISORS]: KnownOrganizationNames.FIVE_STAR_MARKETING_ADVISORS,
    [KnownOrganizationIds.EVIDNT]: KnownOrganizationNames.EVIDNT,
    [KnownOrganizationIds.DISH_SLING]: KnownOrganizationNames.DISH_SLING,
    [KnownOrganizationIds.T_MOBILE]: KnownOrganizationNames.T_MOBILE,
    [KnownOrganizationIds.AI_DIGITAL]: KnownOrganizationNames.AI_DIGITAL,
    [KnownOrganizationIds.TRIP_ADVISOR]: KnownOrganizationNames.TRIP_ADVISOR,
    [KnownOrganizationIds.LIFTOFF]: KnownOrganizationNames.LIFTOFF,
    [KnownOrganizationIds.MiQ]: KnownOrganizationNames.MiQ,
    [KnownOrganizationIds.OMG]: KnownOrganizationNames.OMG,
    [KnownOrganizationIds.OTTERA]: KnownOrganizationNames.OTTERA,
    [KnownOrganizationIds.VOGLIO]: KnownOrganizationNames.VOGLIO,
    [KnownOrganizationIds.ARKADAS_GROUP]: KnownOrganizationNames.ARKADAS_GROUP,
    [KnownOrganizationIds.SAKS_OFF_FIFTH]: KnownOrganizationNames.SAKS_OFF_FIFTH,
    [KnownOrganizationIds.BUCKEYE_BROADBAND]: KnownOrganizationNames.BUCKEYE_BROADBAND,
    [KnownOrganizationIds.LIFEBRANDS_D2C]: KnownOrganizationNames.LIFEBRANDS_D2C,
    [KnownOrganizationIds.KILL_YOUR_COMPETITION]: KnownOrganizationNames.KILL_YOUR_COMPETITION,
    [KnownOrganizationIds.SONOBI]: KnownOrganizationNames.SONOBI,
    [KnownOrganizationIds.BATEMAN_COLLECTIVE]: KnownOrganizationNames.BATEMAN_COLLECTIVE,
    [KnownOrganizationIds.FEDERATED_DIGITAL_SOLUTIONS]: KnownOrganizationNames.FEDERATED_DIGITAL_SOLUTIONS,
    [KnownOrganizationIds.KR8_VENTURES]: KnownOrganizationNames.KR8_VENTURES,
    [KnownOrganizationIds.ESSENTIALISM_ADVERTISING]: KnownOrganizationNames.ESSENTIALISM_ADVERTISING,
    [KnownOrganizationIds.RED_VENTURES]: KnownOrganizationNames.RED_VENTURES,
    [KnownOrganizationIds.HUDDLED_MASSES]: KnownOrganizationNames.HUDDLED_MASSES,
    [KnownOrganizationIds.AUTOMATED_TESTING]: KnownOrganizationNames.AUTOMATED_TESTING,
    [KnownOrganizationIds.GREEN_AND_WOOD_MEDIA]: KnownOrganizationNames.GREEN_AND_WOOD_MEDIA,
    [KnownOrganizationIds.TASTEMADE]: KnownOrganizationNames.TASTEMADE,
    [KnownOrganizationIds.DIGILANT]: KnownOrganizationNames.DIGILANT,
    [KnownOrganizationIds.CHAMPIONSHIP_ADVERTISING]: KnownOrganizationNames.CHAMPIONSHIP_ADVERTISING,
    [KnownOrganizationIds.CTV_BUYER]: KnownOrganizationNames.CTV_BUYER,
    [KnownOrganizationIds.LEV_LANE]: KnownOrganizationNames.LEV_LANE,
    [KnownOrganizationIds.THE_DIGITAL_RUCKUS]: KnownOrganizationNames.THE_DIGITAL_RUCKUS,
    [KnownOrganizationIds.BRAVE]: KnownOrganizationNames.BRAVE,
    [KnownOrganizationIds.VIDEOHEROES]: KnownOrganizationNames.VIDEOHEROES,
    [KnownOrganizationIds.ENTHUSIAST_GAMING]: KnownOrganizationNames.ENTHUSIAST_GAMING,
    [KnownOrganizationIds.CBS]: KnownOrganizationNames.CBS,
    [KnownOrganizationIds.NIMBUS99]: KnownOrganizationNames.NIMBUS99,
    [KnownOrganizationIds.CONTINUUM_MEDIA]: KnownOrganizationNames.CONTINUUM_MEDIA,
    [KnownOrganizationIds.NEXSTAR]: KnownOrganizationNames.NEXSTAR,
    [KnownOrganizationIds.BLAM_RETAIL]: KnownOrganizationNames.BLAM_RETAIL,
    [KnownOrganizationIds.VICA_AI]: KnownOrganizationNames.VICA_AI,
    [KnownOrganizationIds.NIGHTLIFE_TELEVISION]: KnownOrganizationNames.NIGHTLIFE_TELEVISION,
    [KnownOrganizationIds.JRR_ADVERTISING]: KnownOrganizationNames.JRR_ADVERTISING,
    [KnownOrganizationIds.ESTRELLA_MEDIA]: KnownOrganizationNames.ESTRELLA_MEDIA,
    [KnownOrganizationIds.PERFORMCB]: KnownOrganizationNames.PERFORMCB,
    [KnownOrganizationIds.NEW_ROAD_ADVERTISING]: KnownOrganizationNames.NEW_ROAD_ADVERTISING,
    [KnownOrganizationIds.QUICKTURN_MEDIA]: KnownOrganizationNames.QUICKTURN_MEDIA,
    [KnownOrganizationIds.CONNECTADTV]: KnownOrganizationNames.CONNECTADTV,
    [KnownOrganizationIds.NRG_MEDIA]: KnownOrganizationNames.NRG_MEDIA,
    [KnownOrganizationIds.LILLY_BROADCASTING]: KnownOrganizationNames.LILLY_BROADCASTING
};
export const OrganizationsWithStationsByException = [
    KnownOrganizationIds.BAHAKEL
];
export const SERVICE_TO_CLIENT_DEVICE_CAP = {
    [ServiceDeviceNames.DESKTOP]: ClientDeviceNames.DESKTOP,
    [ServiceDeviceNames.MOBILE]: ClientDeviceNames.MOBILE,
    [ServiceDeviceNames.TABLET]: ClientDeviceNames.TABLET,
    [ServiceDeviceNames.CTV]: ClientDeviceNames.CTV
};
export const SERVICE_DEVICE_TO_DEVICE_NAME = {
    [ServiceDeviceNames.CTV]: DeviceNames.CTV,
    [ServiceDeviceNames.DESKTOP]: DeviceNames.DESKTOP,
    [ServiceDeviceNames.MOBILE]: DeviceNames.MOBILE,
    [ServiceDeviceNames.TABLET]: DeviceNames.TABLET
};
/**
 * Set of all organizations identified as "Station Groups".
 */
export const StationGroupOrganizations = new Set([
    KnownOrganizationIds.PREMION,
    KnownOrganizationIds.SCRIPPS,
    KnownOrganizationIds.FOX,
    KnownOrganizationIds.NEWS_PRESS_GAZETTE,
    KnownOrganizationIds.CBC,
    KnownOrganizationIds.COX,
    KnownOrganizationIds.ALLENMEDIA,
    KnownOrganizationIds.GRIFFIN,
    KnownOrganizationIds.HEARST,
    KnownOrganizationIds.CAPITOL_BROADCASTING,
    KnownOrganizationIds.UNIVISION,
    KnownOrganizationIds.SPARKLIGHT,
    KnownOrganizationIds.WIN_DIGITAL,
    KnownOrganizationIds.CHARLTON_MARKETING,
    KnownOrganizationIds.BAHAKEL,
    KnownOrganizationIds.TOWNSQUARE,
    KnownOrganizationIds.NRG_MEDIA,
    KnownOrganizationIds.LILLY_BROADCASTING
]);
export const ORGS_WITH_OPTIONAL_STATION = new Set([
    KnownOrganizationIds.BAHAKEL,
    KnownOrganizationIds.COX,
    KnownOrganizationIds.PREMION,
    KnownOrganizationIds.TOWNSQUARE,
    KnownOrganizationIds.NRG_MEDIA,
    KnownOrganizationIds.LILLY_BROADCASTING
]);
export const URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|{proto}:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/;
export const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,5})?(\/.*)?$/;
export const ROOT_USER_ID = "6Jpb050FNBWf4L6jTChhpHzeGfch";
export const OBJ_TYPE_TO_NAME = {
    [ObjType.UNKNOWN]: "Unknown",
    [ObjType.AGENCY]: "Agency",
    [ObjType.BRAND]: "Brand",
    [ObjType.CAMP]: "Campaign",
    [ObjType.BRAND_GROUP]: "Brand Group",
    [ObjType.AGENCY_GROUP]: "Agency Group",
    [ObjType.WLIST_OVER]: "WList Over",
    [ObjType.SEG]: "Segment",
    [ObjType.TAG]: "Tag",
    [ObjType.IO]: "IO",
    [ObjType.VAST]: "Deal",
    [ObjType.PROD]: "Product",
    [ObjType.LI_FLIGHT]: "Line Item Flight",
    [ObjType.LI]: "Line Item",
    [ObjType.INST]: "Campaign/Line Item",
    [ObjType.ORG]: "Organization",
    [ObjType.PUB]: "Pub",
    [ObjType.DATA_PROVIDER]: "Data Provider",
    [ObjType.CREATIVE_FLIGHT]: "Creative Flight",
    [ObjType.SOURCE]: "Source",
    [ObjType.STATION]: "Station",
    [ObjType.USR]: "User",
    [ObjType.ADV]: "Advertiser",
    [ObjType.WLIST]: "Wlist",
    [ObjType.CONTENT_PROVIDER]: "Content Provider",
    [ObjType.PIXEL]: "Pixel",
    [ObjType.ISP]: "ISP",
    [ObjType.CREATIVE_VID]: "Creative Video",
    [ObjType.SSP]: "SSP",
    [ObjType.GEO_LOC]: "Geo Location",
    [ObjType.ANCESTOR]: "Ancestor",
    [ObjType.BEACON]: "Beacon"
};
