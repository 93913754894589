import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const StandardAudience: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-circled-standard-audience"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        d="M13.3333 9.34227C12.5573 9.34227 12.1693 9.96361 12.1693 11.2063C12.1693 11.8276 12.2739 12.2955 12.4831 12.6098C12.6923 12.9168 12.9757 13.0703 13.3333 13.0703C13.6505 13.0703 13.9237 12.957 14.1532 12.7304C14.3826 12.5038 14.4973 12.2443 14.4973 11.9519V10.4607C14.4973 10.1683 14.3826 9.90879 14.1532 9.68218C13.9237 9.45557 13.6505 9.34227 13.3333 9.34227ZM12.9285 14.1668C12.3279 14.1668 11.8286 13.9037 11.4305 13.3774C11.0324 12.8437 10.8333 12.12 10.8333 11.2063C10.8333 10.2633 11.0222 9.53598 11.4001 9.02429C11.7847 8.50528 12.2942 8.24578 12.9285 8.24578C13.502 8.24578 14.0081 8.47238 14.4467 8.9256C14.4534 8.93291 14.4602 8.93657 14.4669 8.93657C14.4737 8.93657 14.477 8.93291 14.477 8.9256V6.4585C14.477 6.29037 14.5344 6.14417 14.6491 6.0199C14.7638 5.89563 14.8988 5.8335 15.054 5.8335H15.2564C15.4116 5.8335 15.5465 5.89563 15.6612 6.0199C15.776 6.14417 15.8333 6.29037 15.8333 6.4585V13.4322C15.8333 13.6003 15.776 13.7465 15.6612 13.8708C15.5465 13.995 15.4116 14.0572 15.2564 14.0572H15.1451C14.9831 14.0572 14.8448 13.9987 14.7301 13.8817C14.6221 13.7575 14.5648 13.6076 14.558 13.4322L14.5479 13.3554C14.5479 13.3481 14.5445 13.3445 14.5378 13.3445C14.531 13.3445 14.5243 13.3481 14.5175 13.3554C14.0722 13.8964 13.5425 14.1668 12.9285 14.1668Z"
        fill={fill}
      />
      <path
        d="M6.96029 10.6335C5.97576 10.3372 5.26198 9.97794 4.81894 9.55572C4.3841 9.1335 4.16669 8.62238 4.16669 8.02238C4.16669 7.34831 4.41692 6.81498 4.91739 6.42238C5.41786 6.02979 6.11934 5.8335 7.02182 5.8335C7.78483 5.8335 8.44939 5.91127 9.01549 6.06683C9.2124 6.11868 9.36828 6.22609 9.48314 6.38905C9.60621 6.54461 9.66774 6.71868 9.66774 6.91127V7.01127C9.66774 7.15201 9.598 7.26313 9.45853 7.34461C9.31905 7.41868 9.17548 7.42979 9.0278 7.37794C8.41247 7.16313 7.78483 7.05572 7.14489 7.05572C6.73467 7.05572 6.4229 7.1409 6.20958 7.31127C5.99627 7.48164 5.88961 7.71868 5.88961 8.02238C5.88961 8.55572 6.27522 8.9372 7.04643 9.16683C8.15403 9.48535 8.92114 9.84831 9.34777 10.2557C9.7826 10.6631 10 11.1891 10 11.8335C10 13.3891 8.93755 14.1668 6.81261 14.1668C6.14805 14.1668 5.54503 14.0742 5.00354 13.8891C4.80663 13.8224 4.65075 13.7076 4.53589 13.5446C4.42923 13.3816 4.3759 13.2002 4.3759 13.0002V12.8668C4.3759 12.7261 4.44153 12.6261 4.57281 12.5668C4.70408 12.5002 4.83945 12.4965 4.97892 12.5557C5.52041 12.8002 6.09062 12.9224 6.68954 12.9224C7.7315 12.9224 8.25248 12.5668 8.25248 11.8557C8.25248 11.5668 8.15403 11.3298 7.95712 11.1446C7.76022 10.952 7.42794 10.7816 6.96029 10.6335Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.4767 0 0 4.4767 0 10C0 15.5233 4.4767 20 10 20C15.5233 20 20 15.5233 20 10C20 4.4767 15.5233 0 10 0ZM1.53846 10C1.53846 5.32637 5.32637 1.53846 10 1.53846C14.6736 1.53846 18.4615 5.32637 18.4615 10C18.4615 14.6736 14.6736 18.4615 10 18.4615C5.32637 18.4615 1.53846 14.6736 1.53846 10Z"
        fill={fill}
      />
    </svg>
  );
};

export default StandardAudience;
