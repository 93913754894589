import { IToastProps } from "@blueprintjs/core";
import {
  ReportingAdvertiser as Advertiser,
  CampaignsResponse
} from "@madhive/mad-sdk";

export const GET_REPORTING_ADVERTISERS_PENDING =
  "GET_REPORTING_ADVERTISERS_PENDING";
export const GET_REPORTING_ADVERTISERS_SUCCESS =
  "GET_REPORTING_ADVERTISERS_SUCCESS";
export const GET_REPORTING_ADVERTISERS_FAILURE =
  "GET_REPORTING_ADVERTISERS_FAILURE";

export const GET_REPORTING_CAMPAIGNS_PENDING =
  "GET_REPORTING_CAMPAIGNS_PENDING";
export const GET_REPORTING_CAMPAIGNS_SUCCESS =
  "GET_REPORTING_CAMPAIGNS_SUCCESS";
export const GET_REPORTING_CAMPAIGNS_FAILURE =
  "GET_REPORTING_CAMPAIGNS_FAILURE";

export interface ReportingState {
  readonly advertisersById: Record<string, Advertiser>;
  readonly isAdvertisersLoading: boolean;
  readonly campaignsById: Record<string, CampaignsResponse>;
  readonly isCampaignsLoading: boolean;

  readonly error:
    | ({
        message: string;
      } & IToastProps)
    | null;
}

interface GetReportingAdvertisersPendingAction {
  type: typeof GET_REPORTING_ADVERTISERS_PENDING;
}

interface GetReportingAdvertisersSuccessAction {
  type: typeof GET_REPORTING_ADVERTISERS_SUCCESS;
  payload: Record<string, Advertiser>;
}

interface GetReportingAdvertisersFailureAction {
  type: typeof GET_REPORTING_ADVERTISERS_FAILURE;
  meta: {
    error: {
      message: string;
    };
  };
}

interface GetReportingCampaignsPendingAction {
  type: typeof GET_REPORTING_CAMPAIGNS_PENDING;
}

interface GetReportingCampaignsSuccessAction {
  type: typeof GET_REPORTING_CAMPAIGNS_SUCCESS;
  payload: Record<string, CampaignsResponse>;
}

interface GetReportingCampaignsFailureAction {
  type: typeof GET_REPORTING_CAMPAIGNS_FAILURE;
  meta: {
    error: {
      message: string;
    };
  };
}

export type ReportingActionTypes =
  | GetReportingAdvertisersPendingAction
  | GetReportingAdvertisersSuccessAction
  | GetReportingAdvertisersFailureAction
  | GetReportingCampaignsPendingAction
  | GetReportingCampaignsSuccessAction
  | GetReportingCampaignsFailureAction;
