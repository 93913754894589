import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const CustomAudience: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-circled-custom-audience"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.4767 0 0 4.4767 0 10C0 15.5233 4.4767 20 10 20C15.5233 20 20 15.5233 20 10C20 4.4767 15.5233 0 10 0ZM1.53846 10C1.53846 5.32637 5.32637 1.53846 10 1.53846C14.6736 1.53846 18.4615 5.32637 18.4615 10C18.4615 14.6736 14.6736 18.4615 10 18.4615C5.32637 18.4615 1.53846 14.6736 1.53846 10Z"
        fill={fill}
      />
      <path
        d="M13.8134 11.3582C12.7612 11.3582 12.2351 11.6751 12.2351 12.3088C12.2351 12.5393 12.3013 12.7265 12.4338 12.8705C12.5736 13.0146 12.7502 13.0866 12.9636 13.0866C13.383 13.0866 13.7215 12.9606 13.979 12.7085C14.2366 12.4492 14.3653 12.0964 14.3653 11.6499V11.4554C14.3653 11.3906 14.3322 11.3582 14.266 11.3582H13.8134ZM12.5662 14.1668C12.0658 14.1668 11.6501 14.0084 11.319 13.6915C10.9952 13.3674 10.8333 12.9498 10.8333 12.4384C10.8333 11.8119 11.0761 11.3258 11.5618 10.9801C12.0548 10.6344 12.8054 10.4616 13.8134 10.4616H14.266C14.3322 10.4616 14.3653 10.4292 14.3653 10.3644V10.2779C14.3653 9.94666 14.2844 9.71621 14.1225 9.58658C13.9606 9.44975 13.6736 9.38134 13.2616 9.38134C12.8348 9.38134 12.3123 9.45695 11.6942 9.60819C11.5618 9.6442 11.4404 9.62259 11.33 9.54337C11.2196 9.45695 11.1644 9.34533 11.1644 9.2085C11.1644 9.04286 11.2159 8.89522 11.319 8.7656C11.422 8.62876 11.5544 8.54595 11.7163 8.51714C12.2755 8.39471 12.8017 8.3335 13.2947 8.3335C14.2366 8.3335 14.8951 8.49913 15.2704 8.83041C15.6457 9.16169 15.8333 9.74142 15.8333 10.5696V13.4431C15.8333 13.6087 15.7708 13.7527 15.6457 13.8752C15.5279 13.9976 15.3845 14.0588 15.2152 14.0588H15.0938C14.9172 14.0588 14.7664 14.0012 14.6413 13.886C14.5162 13.7635 14.4499 13.6159 14.4426 13.4431V13.2918C14.4426 13.2846 14.4389 13.281 14.4315 13.281C14.4168 13.281 14.4095 13.2846 14.4095 13.2918C13.9606 13.8752 13.3462 14.1668 12.5662 14.1668Z"
        fill={fill}
      />
      <path
        d="M7.97055 14.1668C6.80241 14.1668 5.87455 13.8076 5.18697 13.0891C4.50678 12.3631 4.16669 11.3335 4.16669 10.0002C4.16669 8.68164 4.49569 7.65942 5.1537 6.9335C5.8191 6.20016 6.74696 5.8335 7.93728 5.8335C8.48439 5.8335 8.94277 5.86683 9.31244 5.9335C9.48988 5.96313 9.63775 6.05201 9.75604 6.20016C9.87433 6.34831 9.93348 6.51498 9.93348 6.70016V6.78905C9.93348 6.94461 9.86694 7.06683 9.73386 7.15572C9.60817 7.24461 9.4714 7.27053 9.32353 7.2335C8.96126 7.1372 8.55463 7.08905 8.10363 7.08905C7.37169 7.08905 6.79501 7.3409 6.37359 7.84461C5.95957 8.34831 5.75256 9.06683 5.75256 10.0002C5.75256 10.9261 5.96696 11.6446 6.39577 12.1557C6.83198 12.6594 7.41236 12.9113 8.1369 12.9113C8.62486 12.9113 9.04258 12.8668 9.39007 12.7779C9.53794 12.7409 9.67471 12.7668 9.8004 12.8557C9.93348 12.9446 10 13.0668 10 13.2224V13.3002C10 13.4853 9.94087 13.652 9.82258 13.8002C9.71168 13.9409 9.56381 14.0261 9.37898 14.0557C8.95756 14.1298 8.48809 14.1668 7.97055 14.1668Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomAudience;
