import axios from "axios";
import { Templator } from "../../handlers";
import { templateToServiceTemplate } from "../../../models/creatives/templates";
import { TemplateCreationFailed } from "../../handlers/errors";
class Templates extends Templator {
    constructor(sdk) {
        super(sdk);
        this.createTemplate = async (template) => {
            const key = this.createCacheKey(template);
            let result;
            if (!this.cache.has(key)) {
                try {
                    if (template.type === "cdn") {
                        result = await this.createCDNTemplate(template);
                    }
                    else {
                        result = await this.createDesktopTemplate(template);
                    }
                }
                catch (error) {
                    throw new TemplateCreationFailed(error);
                }
                await this.cache.set(key, result);
            }
            return Promise.resolve(this.cache.get(key));
        };
        this.createCacheKey = (template) => {
            const rowHashes = [];
            if (template.rows) {
                template.rows.forEach((row) => {
                    rowHashes.push("id", row.id, "file", row.file);
                });
            }
            return `type-${template.type}-linear-${template.linear ?? false}-row-${rowHashes.join("-")}-iabCategoryId-${template.iabCategoryId}}`;
        };
        this.createCDNTemplate = async (template) => {
            const route = template.linear ? "linearCDNTemplate" : "cdnTemplate";
            const res = await axios.get(`${this.baseUrl}/${route}`, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/json"
                },
                // n.b. the hard-coded true value is only temporary, see ADOPS-887
                params: { iabCategoryId: template.iabCategoryId }
            });
            return res.data;
        };
        this.createDesktopTemplate = async (template) => {
            const route = template.linear ? "populateLinearTemplate" : "populateTemplate";
            const res = await axios.post(`${this.baseUrl}/${route}`, templateToServiceTemplate(template), {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/json"
                },
                // n.b. the hard-coded true value is only temporary, see ADOPS-887
                params: { iabCategoryId: template.iabCategoryId }
            });
            return res.data;
        };
        this.baseUrl = this.sdk.urls.madhiveEncoderBaseUrl;
    }
}
export default Templates;
