export const PACING_QUERY = {
    view: "explore_pacing",
    fieldMap: {
        "vw_dim_lineitem.advertiser__name": "",
        "vw_dim_lineitem.advertiser__id": "",
        "vw_dim_lineitem.campaign__id": "",
        "vw_dim_lineitem.campaign__name": "",
        "vw_dim_lineitem.campaign_start_date": "",
        "vw_dim_lineitem.campaign_end_date": "",
        "vw_dim_lineitem.product__name": "",
        "vw_dim_lineitem.product__id": "",
        "vw_dim_lineitem.pacing_goal": "",
        "vw_dim_lineitem.lineitem_end_date": "",
        "vw_dim_lineitem.lineitem__id": "",
        "vw_dim_lineitem.lineitem__name": "",
        "vw_dim_lineitem.lineitem_start_date": "",
        "vw_dim_lineitem.lineitem_type": "",
        "vw_fct_impressions.pacing_status": "",
        "vw_fct_impressions.remaining_budget": "",
        "vw_fct_impressions.remaining_impressions": "",
        "vw_dim_lineitem.station__name": "",
        "vw_fct_impressions.total_impressions": "",
        "vw_fct_impressions.total_delivered": "",
        "vw_fct_impressions.total_spend": "",
        "vw_fct_impressions.delivered_goal": "",
        "vw_dim_lineitem.spend_goal": "",
        "vw_dim_lineitem.advertiser__iab_category__raw__name": "",
        "vw_dim_lineitem.advertiser__iab_category__raw__code": "",
        "vw_dim_creative_categories.raw__name": "",
        "vw_dim_creative_categories.raw__code": ""
    },
    pivots: null,
    fill_fields: null,
    filters: {
        "vw_dim_lineitem.campaign__status_name": "READY,PAUSED,CANCELLED",
        "vw_dim_lineitem.lineitem__status_name": "READY,PAUSED,CANCELLED",
        "vw_dim_lineitem.lineitem__id": "",
        "vw_dim_lineitem.advertiser__name": "",
        "vw_fct_impressions.pacing_status": "",
        "vw_dim_lineitem.campaign__id": "",
        "vw_dim_lineitem.lineitem__name": "",
        "vw_dim_lineitem.campaign__name": "",
        "vw_dim_lineitem.advertiser__iab_category__raw__name": "",
        "vw_dim_lineitem.product__name": "",
        "vw_dim_lineitem.flt_analysis_interval": "7 day",
        "vw_lineitem_agg.flt_lineitem_status_by_current_tstamp": "",
        "vw_campaign_agg.flt_campaign_pace_status": "",
        "vw_campaign_agg.flt_delivered_status": "",
        "vw_dim_creative.creative_name": ""
    },
    filter_expression: null,
    sorts: ["vw_dim_lineitem.campaign__name"],
    limit: "5000",
    column_limit: "50",
    total: null,
    row_total: null,
    subtotals: null,
    filter_config: {
        "vw_dim_lineitem.campaign__status_name": [
            {
                type: "=",
                values: [
                    {
                        constant: "READY,PAUSED,CANCELLED"
                    },
                    {}
                ],
                id: 30
            }
        ],
        "vw_dim_lineitem.lineitem__status_name": [
            {
                type: "=",
                values: [
                    {
                        constant: "READY,PAUSED,CANCELLED"
                    },
                    {}
                ],
                id: 31
            }
        ],
        "vw_dim_lineitem.lineitem__id": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 32
            }
        ],
        "vw_dim_lineitem.advertiser__name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 33
            }
        ],
        "vw_fct_impressions.pacing_status": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 34
            }
        ],
        "vw_dim_lineitem.campaign__id": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 35
            }
        ],
        "vw_dim_lineitem.lineitem__name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 36
            }
        ],
        "vw_dim_lineitem.campaign__name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 37
            }
        ],
        "vw_dim_lineitem.advertiser__iab_category__raw__name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 38
            }
        ],
        "vw_dim_lineitem.product__name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 39
            }
        ],
        "vw_dim_lineitem.flt_analysis_interval": [
            {
                type: "past",
                values: [
                    {
                        constant: "7",
                        unit: "day"
                    },
                    {}
                ],
                id: 40
            }
        ],
        "vw_lineitem_agg.flt_lineitem_status_by_current_tstamp": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 41
            }
        ],
        "vw_campaign_agg.flt_campaign_pace_status": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 42
            }
        ],
        "vw_campaign_agg.flt_delivered_status": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 43
            }
        ],
        "vw_dim_creative.creative_name": [
            {
                type: "=",
                values: [
                    {
                        constant: ""
                    },
                    {}
                ],
                id: 44
            }
        ]
    },
    visible_ui_sections: null,
    has_table_calculations: false,
    model: "client_analytics",
    dynamic_fields: "[]",
    query_timezone: null,
    can: {
        cost_estimate: true,
        create: true,
        index: true,
        show: true,
        download: true,
        download_unlimited: true,
        edit_custom_fields: false,
        edit_table_calculations: false,
        explore: false,
        generate_drill_links: true,
        render: true,
        run: true,
        see_results: true,
        save: true,
        schedule: true,
        see_aggregate_table_lookml: false,
        see_derived_table_lookml: false,
        see_lookml: false,
        see_sql: false,
        use_custom_fields: true
    }
};
