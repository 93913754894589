import { useRef } from "react";
import { css } from "@emotion/react";
import { ListWithChildrenElements, WrappedListComponentProps } from "./types";
import Spinner from "../Spinner";
import { useSizingProp } from "../../hooks";
import { DoNotCare } from "../types";
import ListHeader from "./Elements/Header";
import { styles } from "./styles";
import { Display } from "../Icons";
import Icon from "../Icon";
import Button from "../Button";

function BaseList({
  children,
  minHeight: _minHeight,
  maxHeight: _maxHeight,
  width: _width = "100%",
  minWidth: _minWidth,
  maxWidth: _maxWidth,
  containerRef,
  header,
  isLoading = false,
  error,
  gap = 2,
  ...rest
}: ListWithChildrenElements) {
  const minHeight = useSizingProp(_minHeight);
  const maxHeight = useSizingProp(_maxHeight);
  const width = useSizingProp(_width);
  const minWidth = useSizingProp(_minWidth);
  const maxWidth = useSizingProp(_maxWidth);
  const itemRef = useRef<HTMLElement | null>(null);
  const { searchProps: { onRetry } = {} } =
    rest as WrappedListComponentProps<DoNotCare>;

  return (
    <div
      className="kit-List"
      css={[
        css`
          --list-gap: ${gap}px;
          --list-padding-x: var(--spacing-4);
          --list-item-padding: var(--spacing-8);
          --list-max-height: ${maxHeight};
          --list-min-height: ${minHeight};
          --list-width: ${width};
          --list-max-width: ${maxWidth};
          --list-min-width: ${maxWidth};
        `,
        styles.listWrapper,
        { width, minWidth, maxWidth }
      ]}
      ref={(current: DoNotCare) => {
        itemRef.current = current;
        containerRef?.(itemRef.current);
      }}
    >
      {!!(header || error || isLoading) && (
        <ListHeader>
          {header}
          {!!error && (
            <div data-testid="list-info-error" css={[styles.infoItem]}>
              <Icon name="Circled.X" fill="var(--color-danger)" />
              Error loading results.{" "}
              {!!onRetry && (
                <Button variant="ghost" onClick={onRetry}>
                  Retry
                </Button>
              )}
            </div>
          )}
          {!error && isLoading && (
            <div data-testid="list-info-loading" css={[styles.infoItem]}>
              <Display size="small">
                <Spinner />
              </Display>
              <span>Loading results</span>
            </div>
          )}
        </ListHeader>
      )}

      {children}
    </div>
  );
}

export default BaseList;
