import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const RetargetingSegment: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-circled-retargeting-segment"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4004 0C4.87709 0 0.400391 4.4767 0.400391 10C0.400391 15.5233 4.87709 20 10.4004 20C15.9237 20 20.4004 15.5233 20.4004 10C20.4004 4.4767 15.9237 0 10.4004 0ZM1.93885 10C1.93885 5.32637 5.72676 1.53846 10.4004 1.53846C15.074 1.53846 18.8619 5.32637 18.8619 10C18.8619 14.6736 15.074 18.4615 10.4004 18.4615C5.72676 18.4615 1.93885 14.6736 1.93885 10Z"
        fill={fill}
      />
      <path
        d="M11.8188 9.74175C11.6562 9.74175 11.518 9.69175 11.4041 9.59175C11.2903 9.48461 11.2334 9.35961 11.2334 9.21675C11.2334 9.07389 11.2903 8.95246 11.4041 8.85246C11.518 8.75246 11.6562 8.70246 11.8188 8.70246H12.4041C12.4773 8.70246 12.5139 8.67032 12.5139 8.60603V7.27746C12.5139 7.11318 12.5789 6.97032 12.709 6.84889C12.8472 6.72746 13.0098 6.66675 13.1968 6.66675H13.4407C13.6277 6.66675 13.7903 6.72746 13.9285 6.84889C14.0667 6.97032 14.1358 7.11318 14.1358 7.27746V8.60603C14.1358 8.67032 14.1724 8.70246 14.2456 8.70246H15.6358C15.7984 8.70246 15.9367 8.75246 16.0505 8.85246C16.1724 8.95246 16.2334 9.07389 16.2334 9.21675C16.2334 9.35961 16.1724 9.48461 16.0505 9.59175C15.9367 9.69175 15.7984 9.74175 15.6358 9.74175H14.2456C14.1724 9.74175 14.1358 9.77032 14.1358 9.82746V11.9917C14.1358 12.456 14.1927 12.756 14.3066 12.8917C14.4204 13.0275 14.6562 13.0953 15.0139 13.0953C15.209 13.0953 15.3553 13.0882 15.4529 13.0739C15.6155 13.0596 15.7578 13.0917 15.8797 13.1703C16.0017 13.2489 16.0627 13.356 16.0627 13.4917C16.0627 13.6489 16.0017 13.7917 15.8797 13.9203C15.7659 14.0417 15.6236 14.1096 15.4529 14.1239C15.0952 14.1525 14.8269 14.1667 14.648 14.1667C13.8594 14.1667 13.3066 14.0203 12.9895 13.7275C12.6724 13.4275 12.5139 12.8953 12.5139 12.131V9.82746C12.5139 9.77032 12.4773 9.74175 12.4041 9.74175H11.8188Z"
        fill={fill}
      />
      <path
        d="M6.12873 7.22965V9.67334C6.12873 9.7334 6.16268 9.76343 6.23056 9.76343H6.79627C8.13888 9.76343 8.81019 9.28671 8.81019 8.33325C8.81019 7.4849 8.18791 7.06073 6.94335 7.06073C6.71707 7.06073 6.47947 7.0795 6.23056 7.11703C6.16268 7.13205 6.12873 7.16959 6.12873 7.22965ZM5.21229 14.1666C5.03881 14.1666 4.88795 14.1028 4.75972 13.9751C4.6315 13.8475 4.56738 13.6974 4.56738 13.5247V6.64406C4.56738 6.46388 4.62772 6.30247 4.74841 6.15983C4.86909 6.01719 5.01995 5.93836 5.20097 5.92334C5.84211 5.86328 6.46062 5.83325 7.05649 5.83325C8.16528 5.83325 8.99498 6.03971 9.54561 6.45262C10.0962 6.85803 10.3715 7.43235 10.3715 8.17559C10.3715 8.70863 10.2245 9.17785 9.93029 9.58325C9.64366 9.98866 9.25521 10.2739 8.76493 10.4391C8.75739 10.4391 8.75362 10.4429 8.75362 10.4504C8.75362 10.4654 8.75739 10.4729 8.76493 10.4729C9.12698 10.6981 9.43624 11.1411 9.69269 11.8017L10.3715 13.5697C10.4243 13.7124 10.4055 13.8475 10.315 13.9751C10.232 14.1028 10.1151 14.1666 9.96423 14.1666H9.61349C9.41738 14.1666 9.24012 14.114 9.08173 14.0089C8.92333 13.8963 8.81019 13.7499 8.7423 13.5697L8.10871 11.8355C7.97294 11.4751 7.81831 11.2387 7.64483 11.126C7.47135 11.0134 7.17341 10.9571 6.75101 10.9571H6.23056C6.16268 10.9571 6.12873 10.9909 6.12873 11.0585V13.5247C6.12873 13.6974 6.06462 13.8475 5.93639 13.9751C5.81571 14.1028 5.66863 14.1666 5.49514 14.1666H5.21229Z"
        fill={fill}
      />
    </svg>
  );
};

export default RetargetingSegment;
