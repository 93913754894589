import { FilterTypes } from "../handlers/filter";
import { OBJ_TYPE_TO_NAME } from "../../types/constants";
import { addDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import { v4 as uuid } from "uuid";
const PEER_SAVES_FIRESTORE_COLLECTION = "peerChanges";
const PEER_SAVES_FIRESTORE_PATH = ["saves", "orgs"];
/**
 * Tool for tracking data changes by other users with Firestore.
 */
export class PeerSaves {
    /**
     * Constructor.
     * @param sdk Instance of MadSDK.
     */
    constructor(sdk) {
        this.saveUnsubscribers = {};
        /**
         * Keep track of the last time we stopped listening to each resource so that we don't hear repeats.
         * This is done instead of just using the current time because the sdk will cache the data from the
         * first access and a save may have happened after that, while we weren't listening.
         */
        this.stoppedListeningTimestamps = {};
        /**
         * Sends information about a save to Firestore.
         * @param resourceId Unique identifier of the data.
         */
        this.save = (resourceId) => {
            if (!this.user)
                return;
            const savedChanges = {
                resourceId,
                userId: this.user?.id,
                timestamp: Date.now(),
                instanceId: this.instanceId
            };
            addDoc(collection(this.sdk.madFire.firestore, PEER_SAVES_FIRESTORE_COLLECTION, ...PEER_SAVES_FIRESTORE_PATH, this.user.primaryOrganizationId, resourceId), savedChanges);
        };
        /**
         * Initialize Firestore snapshot session for saved made by other users.
         * @param resourceId The unique identifier for this data.
         * @param onSave Callback that is executed when a peer saves data.
         */
        this.startListening = (resourceId, onSave) => {
            if (!this.user)
                return;
            this.stopListening(resourceId);
            this.saveUnsubscribers[resourceId] = onSnapshot(query(collection(this.sdk.madFire.firestore, PEER_SAVES_FIRESTORE_COLLECTION, ...PEER_SAVES_FIRESTORE_PATH, this.user.primaryOrganizationId, resourceId), where("timestamp", ">", this.stoppedListeningTimestamps[resourceId] || Date.now())), async (snapshot) => {
                const documents = snapshot.docChanges();
                for (const document of documents) {
                    if (document.type !== "added")
                        return;
                    const changes = document.doc.data();
                    // Ignore saves by the same instance.
                    // Note: Firestore restrictions don't allow for this logic in the query.
                    if (changes.instanceId === this.instanceId) {
                        return;
                    }
                    const typeId = await this.sdk.cryptography.getTypeFromKey(changes.resourceId);
                    onSave({
                        user: await this.sdk.users.find_once({
                            where: [{ field: "id", type: FilterTypes.EQ, value: changes.userId }]
                        }),
                        timestamp: new Date(changes.timestamp),
                        resourceId: changes.resourceId,
                        dataType: OBJ_TYPE_TO_NAME[typeId],
                        dataTypeId: typeId
                    });
                }
            });
        };
        /**
         * Unsubscribe from Firestore snapshot session for saves made by other users.
         * @param resourceId The unique identifier for this data.
         * @param onSave Callback that is executed when a peer saves data.
         */
        this.stopListening = (resourceId) => {
            if (!this.isListening(resourceId))
                return;
            this.saveUnsubscribers[resourceId]();
            delete this.saveUnsubscribers[resourceId];
            this.stoppedListeningTimestamps[resourceId] = Date.now();
        };
        /**
         * Determines whether or not the given resourceId has an active startListening session.
         * @param resourceId The unique identifier for this data.
         */
        this.isListening = (resourceId) => !!this.saveUnsubscribers[resourceId];
        this.sdk = sdk;
        this.user = sdk.getCurrentUser();
        this.instanceId = uuid();
        if (!this.user) {
            const authSubscription = sdk.authentication.onAuthStateChanged({
                next: (account) => {
                    if (account?.user) {
                        authSubscription.unsubscribe();
                        this.user = sdk.getCurrentUser();
                    }
                }
            });
        }
    }
}
