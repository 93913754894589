import { useRef, useMemo } from "react";
import { DropdownProps, ItemType, SelectedType } from "./types";
import { styles } from "./styles";
import { defaultLookupId, defaultLookupLabel } from "../utils";
import List from "../List";

/**
 * `BaseDropdown` component provides the core functionality for a Dropdown,
 * and is used as the base for layering on functionality through HOCs.
 */
function BaseDropdown<Selected extends SelectedType, Item extends ItemType>({
  cannotClear = false,
  pinned,
  error,
  items,
  sections,
  max,
  selected,
  fixed,
  minHeight,
  height = "70vh",
  minWidth = "240px",
  maxWidth = "none",
  width = "auto",
  fill,
  searchable = false,
  search,
  onItemClick,
  lookupId = (item: Item) => defaultLookupId(item) as string,
  lookupLabel = defaultLookupLabel,
  lookupSection = () => "",
  lookupIcon,
  onItemBlur,
  onItemFocus,
  onDropdownBlur,
  containerRef,
  isStyled = true,
  hideSelected = false,
  isVirtualized = items.length > 100,
  onSelect,
  children
}: DropdownProps<Selected, Item>) {
  const isMulti = Array.isArray(selected);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const isLoading = !!search?.isLoading;

  const handleOnChange = (value: string[]) => {
    const nextValue = (isMulti ? value : value.pop()) as Selected;
    onSelect(nextValue);
  };

  const handleOnItemKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    item: Item
  ) => {
    const itemId = lookupId(item);
    const lastId = items[items.length - 1] && lookupId(items[items.length - 1]);
    if (lastId !== itemId) {
      return;
    }
    if (e.key === "ArrowDown" || (e.key === "Tab" && !e.shiftKey)) {
      onDropdownBlur?.("next");
    }
  };

  const searchProps = useMemo(() => {
    if (search) {
      return {
        ...search,
        focusOnMount: search?.focusOnMount ?? !!children,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>): void => {
          if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
            onDropdownBlur?.("prev");
          }
          search?.onKeyDown?.(e);
        }
      };
    }
    return undefined;
  }, [search, !!children]);

  const selectedIds = useMemo(() => {
    const selectedIds: string[] = [];
    if (selected) {
      selectedIds.push(
        ...[...(Array.isArray(selected) ? selected : [selected])]
      );
    }
    return selectedIds;
  }, [selected]);

  return (
    <div
      className="kit-Dropdown"
      ref={current => {
        dropdownRef.current = current;
        containerRef?.(current);
      }}
      role="menu"
      css={[isStyled && styles.base, fill && styles.fill]}
    >
      <List
        data={items}
        selected={{
          value: selectedIds,
          onChange: handleOnChange,
          hideCount: !isMulti,
          pinned,
          hideSelected,
          cannotClear,
          max,
          fixed
        }}
        lookupSection={item => (item && lookupSection(item)) || ""}
        isLoading={isLoading}
        isSearchable={searchable}
        searchProps={searchProps}
        error={error}
        isVirtualized={isVirtualized}
        sections={sections}
        itemProps={{
          id: item => (item && lookupId(item)) || "",
          label: lookupLabel,
          onClick: onItemClick,
          onBlur: onItemBlur,
          onFocus: onItemFocus,
          isDisabled: item => !item || fixed?.has(lookupId(item)!) || false,
          prefix: item => (item ? lookupIcon?.(item) : undefined),
          onKeyDown: handleOnItemKeyDown
        }}
        maxHeight={height}
        minHeight={minHeight}
        width={width}
        minWidth={minWidth}
        maxWidth={maxWidth}
      />
    </div>
  );
}

export default BaseDropdown;
