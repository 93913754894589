import { useEffect, useState } from "react";
import { LookupMethod } from "../types";

/**
 * This hook keeps track of selected items as an array of items.
 * It is intended to be used used in conjunction with useItemsMap
 * inside components that work with an array of items that utilize lookupId to match those items.
 * @param items array of items to set in map
 * @param lookupId method for looking up item's id
 * @param options optional {  keepIds?: string[];  overrideMap?: Map<string, Item> | undefined}
 */
export const useSelectedItems = <Item = unknown>(
  /** map of items keyed to their id */
  itemsMap: Map<string, Item>,
  /** method for looking up item's id */
  lookupId: LookupMethod<string, Item>,
  /** array of selected item ids */
  ids: string[]
): Item[] => {
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [previousMap, setPreviousMap] = useState<Map<string, Item>>(itemsMap);

  useEffect(() => {
    const items: Item[] = [];
    for (let i = 0; i < ids.length; i++) {
      let item: Item | undefined =
        itemsMap.get(ids[i]) || previousMap.get(ids[i]);
      if (item) {
        items.push(item);
        continue;
      }
      item = selectedItems.find(item => lookupId(item) === ids[i]);
      if (item) {
        items.push(item);
      }
    }
    setSelectedItems(items);
    setPreviousMap(itemsMap);
  }, [itemsMap, ids]);

  return selectedItems;
};
