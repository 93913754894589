import { usePeerChanges } from "@madhive/mad-hooks";
import { useDataChangedToast } from "frontier/lib/hooks/useDataChangedToast";

export const PeerChanges = () => {
  // Notify users when the data they are accessing has been changed by another user.
  const peerChanges = usePeerChanges();
  useDataChangedToast(peerChanges.saved);

  return null;
};
