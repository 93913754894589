import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const FirstPartySegment: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-circled-first-party-segment"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4004 0C4.87709 0 0.400391 4.4767 0.400391 10C0.400391 15.5233 4.87709 20 10.4004 20C15.9237 20 20.4004 15.5233 20.4004 10C20.4004 4.4767 15.9237 0 10.4004 0ZM1.93885 10C1.93885 5.32637 5.72676 1.53846 10.4004 1.53846C15.074 1.53846 18.8619 5.32637 18.8619 10C18.8619 14.6736 15.074 18.4615 10.4004 18.4615C5.72676 18.4615 1.93885 14.6736 1.93885 10Z"
        fill={fill}
      />
      <path
        d="M11.9485 7.22965V9.94361C11.9485 9.99616 11.9784 10.0299 12.0382 10.045C12.3224 10.0825 12.5991 10.1013 12.8683 10.1013C13.4741 10.1013 13.934 9.96989 14.2481 9.70712C14.5697 9.43685 14.7305 9.05397 14.7305 8.55848C14.7305 7.55998 14.1098 7.06073 12.8683 7.06073C12.5991 7.06073 12.3224 7.0795 12.0382 7.11703C11.9784 7.13205 11.9485 7.16959 11.9485 7.22965ZM11.0286 14.1666C10.8566 14.1666 10.707 14.1028 10.5799 13.9751C10.4602 13.8475 10.4004 13.6974 10.4004 13.5247V6.64406C10.4004 6.46388 10.4602 6.30247 10.5799 6.15983C10.6995 6.01719 10.8491 5.93836 11.0286 5.92334C11.6643 5.86328 12.2775 5.83325 12.8683 5.83325C13.9901 5.83325 14.8315 6.05848 15.3924 6.50893C15.9533 6.95187 16.2337 7.59752 16.2337 8.44586C16.2337 9.3843 15.9608 10.0938 15.4148 10.5742C14.8764 11.0547 14.0761 11.295 13.0142 11.295C12.7674 11.295 12.4421 11.2799 12.0382 11.2499C11.9784 11.2499 11.9485 11.2799 11.9485 11.34V13.5247C11.9485 13.6974 11.8849 13.8475 11.7578 13.9751C11.6306 14.1028 11.4811 14.1666 11.309 14.1666H11.0286Z"
        fill={fill}
      />
      <path
        d="M5.11885 8.90403C5.00447 8.98774 4.88193 8.99916 4.75121 8.93827C4.62866 8.87739 4.56738 8.78226 4.56738 8.65289V8.19627C4.56738 7.77009 4.75121 7.42381 5.11885 7.15745L6.43013 6.22138C6.79777 5.96263 7.21444 5.83325 7.68013 5.83325H8.04777C8.23568 5.83325 8.395 5.89794 8.52572 6.02732C8.6646 6.15669 8.73405 6.3089 8.73405 6.48394V13.5159C8.73405 13.6909 8.6646 13.8431 8.52572 13.9725C8.395 14.1019 8.23568 14.1666 8.04777 14.1666H7.68013C7.49222 14.1666 7.32882 14.1019 7.18993 13.9725C7.05104 13.8431 6.9816 13.6909 6.9816 13.5159V7.59124L6.96934 7.57983L6.95709 7.59124L5.11885 8.90403Z"
        fill={fill}
      />
    </svg>
  );
};

export default FirstPartySegment;
