import { FocusEventHandler, FocusEvent, MouseEventHandler, Ref } from "react";
import { DoNotCare } from "../../types";
import { DropdownProps } from "../Dropdown/types";
import { LookupMethod } from "../types";
import { IconNames } from "../Icons/types";
import { FormFieldProps, ReadonlyFormFieldProps } from "../FormField/types";
import { ChipsSetFocusRef } from "../Chips";

export type SelectProps<
  Selected extends string[] | string | undefined,
  Item extends Record<string, DoNotCare> | string
> = Pick<
  FormFieldProps,
  | "description"
  | "error"
  | "hideLabel"
  | "required"
  | "labelSuffix"
  | "labelSuffixAlign"
  | "offsetError"
> & {
  /** label of legend associated with fieldset */
  label?: string;
  /**
   * Selected id(s)
   * @type string | undefined: For a single Select, pass string || undefined to `selected`
   * @type string[]: For a multi Select, pass string[] to `selected`
   */
  selected: Selected;
  /**
   * Callback when an option is selected/deselected
   * @param value: selected id or undefined
   */
  onSelect: (value: Selected) => void;
  /** Possible options to select from */
  options: Item[];
  /**
   * Whether Dropdown should hide the selected option(s) from the list
   * @default false
   */
  hideSelected?: boolean;
  /** Selections from `options` that are always selected, and cannot be de-selected. */
  fixed?: Selected extends DoNotCare[] ? string[] : never;
  /**
   * If false, clicking on the selected item will unselect it
   * If true in single selection mode, the user cannot unselect the selected item, they must select another item
   * It is not supported or available in multiselect.
   * @default false
   */
  cannotClear?: Selected extends string | undefined ? boolean : undefined;
  /** Max number of selections user can make */
  max?: Selected extends any[] ? number : undefined;
  /** Whether Select is disabled */
  disabled?: boolean;
  /** Width of target and dropdown */
  width?: number | string;
  /**
   * Height in pixels for the dropdown
   */
  height?: number | string;
  /**
   * Min width of target and dropdown, either in pixels, or one of the supported CSS keyword values
   */
  minWidth?: number | string;
  /**
   * Whether the Select should fill the width of it's space
   * @default false
   */
  fill?: boolean;
  /** If true, the opener and dropdown will base the width on the width of the widest option. */
  fillToWidestOption?: boolean;
  /**
   * Focus Select target when component mounts
   * @default false
   */
  focusOnMount?: boolean;
  /**
   * Props of the flyout that wraps the dropdown.
   * @see {@link DropdownProps.flyoutProps}
   */
  flyoutProps?: DropdownProps<Selected, Item>["flyoutProps"];
  /**
   * If the select should lock the width of the dropdown to the given minwidth (or target width)
   */
  lockedWidth?: boolean;
  /**
   * If true, the dropdown will display the selected option(s) above the selection section
   * Only works for multiple selection mode
   */
  pinned?: Selected extends string | undefined ? undefined : boolean;
  /**
   * whether list of options can be filtered via search input
   * @default false
   */
  searchable?: boolean;
  /**
   * useSearcher settings
   * @see {@link DropdownProps.search}
   */
  search?: DropdownProps<Selected, Item>["search"];
  /**
   * Unique id for the Select
   * @default `kit-${uuid()}`;
   */
  id?: string;
  /**
   * Should render a select all option
   * @default false
   */
  readonly?: boolean;
  /**
   * override ReadonlyFormField's value parsing
   * @type {(value: string[]) => ReactNode}
   * @param value: array of selected ids
   */
  readonlyParser?: ReadonlyFormFieldProps["parser"];
  /**
   * When an item is passed, this function returns the id of the item
   */
  lookupId: LookupMethod<string, Item>;
  /**
   * When an item is passed, this function returns the label to display
   */
  lookupLabel: LookupMethod<string, Item>;
  /**
   * When an item is passed, this function returns a string
   */
  lookupIntent?: LookupMethod<string, Item>;
  /**
   * When an item is passed, this function returns the icon to display
   * If no icon is available, it returns undefined
   */
  lookupIcon?: LookupMethod<IconNames | JSX.Element | undefined, Item>;
  /** Event handler when Select target is clicked — will not be called if readonly || disabled is true */
  onClick?: MouseEventHandler<HTMLElement>;
  /** Event handler when Select target is focused — will not be called if readonly || disabled is true */
  onFocus?: FocusEventHandler<HTMLElement>;
  /** Event handler when Select target is blurred — will not be called if readonly || disabled is true */
  onBlur?: FocusEventHandler<HTMLElement>;
  /** @returns separator that goes between each selected items in target */
  selectedSeparator?: Selected extends any[]
    ? () => JSX.Element | string
    : undefined;
  /**
   * Whether the dropdown is virtualized to improve performance
   */
  isVirtualized?: boolean;
};

export const isPropsSingleSelect = (args: {
  selected: string[] | string | undefined;
  onSelect: (selected: string[] | string | undefined) => void;
}): args is {
  selected: string | undefined;
  onSelect: (selected: string | undefined) => void;
} => {
  return !Array.isArray(args.selected);
};

export const isPropsMultiSelect = (args: {
  selected: string[] | string | undefined;
  onSelect: (selected: string[] | string | undefined) => void;
}): args is {
  selected: string[];
  onSelect: (selected: string[]) => void;
} => {
  return Array.isArray(args.selected);
};

export type SelectTargetSetFocusRef = {
  chipIndex?: ChipsSetFocusRef | null;
  target: () => void;
};

export type SelectTargetProps<
  Selected extends string | undefined | string[],
  Item extends string | Record<string, DoNotCare>
> = {
  /**
   * Selected id(s)
   * @type string | undefined: For a single Select, pass string || undefined to `selected`
   * @type string[]: For a multi Select, pass string[] to `selected`
   */
  selected: Selected;
  /** Possible options to select from */
  options: Item[];
  /** Whether flyout is open */
  isOpen: boolean;
  /**
   * Callback when an option is selected/deselected
   * @param value: selected id or undefined
   */
  onSelect: (value: Selected) => void;
  /** When an item is passed, this function returns the id of the item */
  lookupId: LookupMethod<string, Item>;
  /** When an item is passed, this function returns the label to display */
  lookupLabel: LookupMethod<string, Item>;
  /**
   * When an item is passed, this function returns a string
   */
  lookupIntent?: LookupMethod<string, Item>;
  /** set target width in parent */
  setTargetWidth: (width: number) => void;
  onBlur: (
    direction: "next" | "prev" | undefined,
    event?: FocusEvent<HTMLElement> | undefined
  ) => void;
  /** map of items keyed to their id */
  itemsMap: Map<string, Item>;
  /** array of selected ids */
  selectedIds: string[];
  setFocusRef?: Ref<SelectTargetSetFocusRef>;
  /**
   * Focus Select target when component mounts
   * @default false
   */
  focusOnMount?: boolean;
  /** Width of target and dropdown */
  width?: string | number | undefined;
  /** Min width of target and dropdown, either in pixels, or one of the supported CSS keyword values */
  minWidth?: string | number | undefined;
  /**
   * Whether the Select should fill the width of it's space
   * @default false
   */
  fill?: boolean;
  /** If true, the opener and dropdown will base the width on the width of the widest option. */
  fillToWidestOption?: boolean;
  /**
   * Should render a select all option
   * @default false
   */
  readonly?: boolean;
  /** FormField: Label text. Will be associated with child input unless `fieldset` is true. */
  label?: string | undefined;
  /** Whether Select is disabled */
  disabled?: boolean;
  /** Error text indicating invalid field/fields. */
  error?: boolean;
  /** Event handler when Select target is focused — will not be called if readonly || disabled is true */
  onFocus?: SelectProps<Selected, Item>["onFocus"];
  /** Event handler when Select target is clicked — will not be called if readonly || disabled is true */
  onClick?: SelectProps<Selected, Item>["onClick"];
} & (Selected extends DoNotCare[]
  ? {
      /**
       * When an item is passed, this function returns the icon to display
       * If no icon is available, it returns undefined
       */
      lookupIcon?: SelectProps<Selected, Item>["lookupIcon"];
      /** @returns separator that goes between each selected items in target */
      selectedSeparator?: SelectProps<Selected, Item>["selectedSeparator"];
      /** Set of ids for "fixed" (or readonly) items  */
      fixed?: Set<string>;
      cannotClear?: never;
    }
  : {
      /**
       * If false, clicking on the selected item will unselect it
       * If true in single selection mode, the user cannot unselect the selected item, they must select another item
       * It is not supported or available in multiselect.
       * @default false
       */
      cannotClear?: boolean;
      lookupIcon?: never;
      selectedSeparator?: never;
      fixed?: never;
    });
